import "./addBundles.css";
import { Box, Typography, InputBase, Button,  Grid,  Stack, Skeleton, Avatar, Breadcrumbs, Tooltip } from "@mui/material"
import MessageBar from "../modules/messageBar"
import { formStyle } from "../modules/profile";
import { updateBPIntialState } from "../redux/bundleProduct";
import { useDispatch, useSelector } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { PickList } from "primereact/picklist";
import { Editor } from "primereact/editor";
import CustomAxios from "../utils/CustomAxios";
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from "../redux/snackbar";
import { Add, Close } from "@mui/icons-material";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { updateAllProduct } from "../redux/products";
import IndianNumFormat from "./numberFormat";

export const Styles = {
    header: { fontSize: "18px",lineHeight:"28px",fontWeight:500 },
    subheader: { fontSize: "14px", color: "#000000",fontWeight:600, lineHeight:"20px" }
}



const AddBundles = ()=>{

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [errors, setErrors] = useState({})
    const admin_details = jwtDecode(localStorage.getItem('authtoken'))
    const bundles = useSelector(state => state.bundles.bundles)
    const bundle = useSelector(state => state.bundles.bundle)
    console.log("------bundle------",bundle)
    const products = useSelector(state => state.product.Allproducts)
    const [sourceProducts,setSourceProducts] = useState(products?products:[])
    const EditorQuillRef = useRef()
    const [isloading,setIsloading] = useState()
    const fileRef = useRef()
    const [isBundleDrafted,setIsBundleDrafted] = useState(false)

    const FindOOSv = (target)=>{
        let isOOS = false
        target?.map((obj)=>{
            if(!obj?.inventry_details || ( obj?.inventry_details && obj?.inventry_details?.available_quantity === 0)){
                isOOS = true
            }
        })
        return isOOS
    }

    const FindProductsDraft = (target)=>{
        let draft = target?.filter((obj)=>obj.status.toLowerCase() === "draft")
        if(target?.length>=1 && draft?.length>=1){
            setIsBundleDrafted(true)
            dispatch(updateSnackBarMessage("One or more of the selected products are in draft status."))
            dispatch(updateSnackBarOpen(true))
            dispatch(updateSnackBarSeverity('warning'))
        }
        else{
            setIsBundleDrafted(false)
        }
    }

    const handleProductSelection = (e)=>{
        //////////////////// val will be array of selected products.
        console.log("-----target on change--------",e.target)
        FindProductsDraft(e.target)
        setSourceProducts(e.source)
        dispatch(updateBPIntialState({key:"bundle",value:{
            ...bundle,
            "products":e.target,
            "price":e.target?.reduce((acc,cv)=>{return acc + parseFloat(cv.selling_price)},0),
            "out_of_stock":FindOOSv(e.target)
        }}))

    }

    const ValidateData = ()=>{
        let errors = {}
        let isvalid = true
        if(!bundle.name || !bundle.name?.trim()){
            errors.name = "Bundle name is required."
            isvalid = false
        }
        if(bundle.name?.trim()?.length < 3){
            errors.name = "Bundle name must be 3 or more characters."
        }
        if(!bundle.products || bundle.products?.length < 2){
            errors.products = "Select atleast two products."
            isvalid = false
        }
        if (!EditorQuillRef.current?.getQuill()?.root?.innerText?.trim()){
            // // console.log("it s happening")
            errors.description="Bundle description is required."
            isvalid = false
        }
        if(parseFloat(bundle.discount) > parseFloat(bundle.price)){
            errors.sellingPrice = "Selling price must be less than price."
            isvalid = false
        }
        // if(!bundle.cover){
        //     errors.cover = "Upload thumbnail image."
        //     isvalid = false
        // }

        setErrors(errors)
        return isvalid
    }

    const handleFileCheck = (file,files)=>{
        if(file && file.type !== "" && file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg'){
            dispatch(updateBPIntialState({ key:"bundle","value":{...bundle, cover: file} }))
        }
        if(files?.length>=1){
            let values = files.filter((file)=> file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg')
            return values.length>=1?false:true
        }

    }

    const handleBundle = (e,status)=>{
        e.preventDefault();
        const formData = new FormData()
        if (bundle.cover){
            formData.append('file',bundle.cover || null)
        }
        Object.entries({
            "user_id":admin_details.id,
            "name": bundle.name,
            // "slug_name":"bundle-"+(bundles?.length + 1),
            "products":JSON.stringify(bundle.products),
            "products_count":bundle.products?.length,
            "description": bundle.description,
            "price": parseFloat(bundle.price),
            "discount": parseFloat(bundle.discount) || 0,
            "status": status,
            "out_of_stock":FindOOSv(bundle.products)
        }).forEach(([key, value]) => {
            formData.append(key, value);
        })
        const validate = ValidateData()
        if(validate){
            setIsloading(true)
            CustomAxios.post(`bundles/`,formData).then((response)=>{
                let res = response.data
                if(res.error){
                    dispatch(updateSnackBarMessage("Bundle creation failed."))
                    dispatch(updateSnackBarSeverity('error'))
                }
                else{
                    dispatch(updateSnackBarMessage("Bundle created successfully."))
                    dispatch(updateSnackBarSeverity('success'))
                    navigate('/product')
                    
                }
                dispatch(updateSnackBarOpen(true))
                setIsloading(false)
            }).catch((err)=>{
                setIsloading(false)
                dispatch(updateSnackBarMessage("Bundle creation failed."))
                dispatch(updateSnackBarSeverity('error'))
                dispatch(updateSnackBarOpen(true))
            })
        }
    }

    const getProducts = ()=>{
        CustomAxios.get('products/')
          .then((res) => {
            if (res.data) {
              dispatch(updateAllProduct(res.data))
    
            }
          })
          .catch(err => console.error(err))
      }

    useEffect(()=>{
        getProducts()
    },[])

    return(
        <Box sx={{ padding: "48px 32px ", width: "100%", display: "flex", gap: "32px", flexDirection: "column",  overflowY: "scroll",bgcolor:"#FFF" }}>
            <MessageBar />
            <Stack gap={"16px"}>
                <Typography sx={Styles.header}>
                    Inventory Management
                </Typography>
                {/* <Divider  sx={{marginTop:"32px",border:"1px dashed grey"}}/> */}
                <Breadcrumbs separator=">" sx={{pr:"8px"}}>
                    <Box color="inherit" sx={{fontSize:"14px",fontWeight:400,lineHeight:"20px",cursor:"pointer"}} onClick={()=>{navigate('/product')}} >Products</Box>
                    <Typography sx={{color:"#1099F4",fontWeight:700,fontSize:"14px",lineHeight:"20px"}}>Create Bundle</Typography>
                </Breadcrumbs>
            </Stack>
            <Stack className="main-section" gap={"36px"}>
                <Stack gap="7px">
                    <Typography sx={[Styles.subheader, { alignSelf: "start" }]}> Bundle Products </Typography>
                    <Stack direction={{xs:"column",md:"row"}} sx={{gap:{xs:"36px",lg:"52px"}}}>
                        <Stack sx={{width:{md:"100%"},gap:"20px"}}>
                            <Box>
                                <Typography sx={formStyle.label} color="grey.700" > Bundle Name <Typography component="span" color="error">*</Typography></Typography>
                                <InputBase sx={formStyle.inputBase} value={bundle.name || ''} onChange={(e) => dispatch(updateBPIntialState({key:"bundle",value:{ ...bundle, name: e.target.value} }))} />
                                {errors.name && <Typography sx={{ color: "error.light" }}>{errors.name}</Typography>}
                            </Box>

                            <Stack>
                                <Typography sx={formStyle.label} color="grey.700" > Products for Bundle <Typography component="span" color="error">*</Typography></Typography>
                                <PickList
                                    dataKey="id"
                                    source={sourceProducts}
                                    sourceHeader={"Available"}
                                    target={bundle.products || []}
                                    targetHeader={
                                        <Stack direction={'row'} sx={{alignItems:"center",justifyContent:"space-between"}}>
                                            <Typography sx={{fontWeight:600}}>Selected</Typography>
                                            <Typography sx={{fontWeight:600}}>{bundle.price && IndianNumFormat(bundle.price,true)}</Typography>
                                        </Stack>
                                    }
                                    onChange={(e)=>{handleProductSelection(e)}}
                                    itemTemplate={(item)=>{
                                        return(
                                            <Stack direction={'row'} sx={{gap:"12px"}}>
                                                <Box component={'img'} src={item?.cover_details?.original} sx={{width:"40px",height:"40px"}}/>
                                                <Stack>
                                                    <Typography sx={{fontFamily:"Inter",fontSize:"14px",fontWeight:600,lineHeight:"20px"}}>{item.name}</Typography>
                                                    <Typography sx={{fontFamily:"Inter",fontSize:"14px",fontWeight:400,lineHeight:"20px"}}>{item.selling_price}</Typography>
                                                </Stack>
                                            </Stack>
                                        )
                                    }}
                                    sourceStyle={{height:"421px"}}
                                    targetStyle={{height:"421px"}}
                                />
                                {errors.products && <Typography sx={{color:"error.light"}}>{errors.products}</Typography>}
                            </Stack>

                            <Box>
                                <Typography sx={formStyle.label} color="grey.700">Description <Typography component="span" color="error">*</Typography></Typography>  

                                <Editor ref={EditorQuillRef}  style={{...formStyle.textBox,borderColor:"#CBD5E1",height:"250px",width:"100%",borderTop:"none",borderTopRightRadius:0,borderTopLeftRadius:0}}  value={bundle.description || ''} 
                                onTextChange={(e) =>{
                                    dispatch(updateBPIntialState({key:"bundle",value:{ ...bundle, description: e.htmlValue} }))
                                }} 
                                />

                                {errors.description && <Typography sx={{color:"error.light"}}>{errors.description}</Typography>}
                            </Box>

                            {/* <Stack direction={{xs:"column",md:"row"}} gap={"24px"} sx={{justifyContent:"space-between",alignItems:"center"}}>
                                <Box sx={{width:"100%"}}>
                                    <Typography sx={formStyle.label} color="grey.700">Discount price </Typography>
                                    <InputBase sx={formStyle.inputBase} value={bundle.discount || ''} onChange={(e) => dispatch(updateBPIntialState({key:"bundle",value:{ ...bundle, discount: e.target.value.replace(/\D/g, '')} }))} />
                                    {errors.dis_price && <Typography sx={{ color: "error.light" }}>{errors.dis_price}</Typography>}
                                </Box>
                                <Box sx={{width:"100%"}}>
                                    <Typography sx={formStyle.label} color="grey.700">Total price </Typography>  
                                    <InputBase readOnly sx={formStyle.inputBase} value={bundle.price || ''} />
                                    {errors.price && <Typography sx={{ color: "error.light" }}>{errors.price}</Typography>}
                                </Box>
                            </Stack> */}

                        </Stack>
                    </Stack>
                </Stack>
                <Stack gap={"7px"}>
                    <Typography sx={[Styles.subheader, { alignSelf: "start" }]}> Cover / Thumbnail </Typography>
                    <Grid container direction="row" sx={{ gap: "1rem", pt: "20px" }}>
                        {!bundle?.cover ?<Stack onClick={() => { fileRef.current && fileRef.current.click() }} sx={{width:"160px",height:"150px",justifyContent:"center",alignItems:"center",border: "1px solid #D6DAE2",bgcolor:"#EDF1F9",cursor:"pointer",borderRadius:"10px"}}>
                            <Add></Add>
                            <input ref={fileRef} accept=".png, .jpg, .jpeg" type="file" onChange={(e) => { handleFileCheck(e.target.files[0],null);}} style={{ display: "none" }} />
                        </Stack>:
                            <Grid item sx={{position:"relative","&:hover .cover-overlay":{display:"flex"}}}>
                                <img src={URL.createObjectURL(bundle?.cover)} style={{width:"160px",height:"150px",padding:"8px",border:"1px solid #DBE3F0",borderRadius:"10px"}}/>
                                <Avatar className="cover-overlay" sx={{display:"none",position:"absolute",top:0,right:0,cursor:"pointer",width:24,height:24,"&:hover":{bgcolor:"#1099F4"}}}><Close  color="#DBE3F0" sx={{fontSize:"14px"}} onClick={(e)=> dispatch(updateBPIntialState({key:"bundle",value:{ ...bundle, cover: null} })) }  /></Avatar>
                            </Grid>
                        }
                    </Grid>
                    {errors.cover && <Typography sx={{ color: "error.light" }}>{errors.cover}</Typography>}
                </Stack>
                <Box sx={{bgcolor:"#F8F9FF",p:"20px 24px 20px 24px"}}>
                    {
                        isloading?
                            <Stack direction={"row"} sx={{gap:"24px",justifyContent:"flex-end"}}>
                                <Skeleton variant="rounded" width={80} height={37}>
                                    <Button variant="contained" sx={{textTransform:"capitalize"}}>Publish</Button>
                                </Skeleton>
                                <Skeleton variant="rounded" width={105} height={37}>
                                    <Button variant="outlined" sx={{textTransform:"capitalize"}}>Save Draft</Button>
                                </Skeleton>
                            </Stack>
                        :
                            <Stack direction={"row"} sx={{gap:"24px",justifyContent:"flex-end"}}>
                                <Button disabled={isBundleDrafted} variant="contained" sx={{textTransform:"capitalize"}} onClick={(e)=>handleBundle(e,1)}>Publish </Button>
                                <Button variant="outlined" sx={{textTransform:"capitalize"}}  onClick={(e)=>handleBundle(e,0)}>Save Draft</Button>
                            </Stack>
                    }
                </Box>
            </Stack>
        </Box>
    )

}

export default AddBundles