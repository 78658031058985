import { Box, Grid, Stack, Typography } from "@mui/material"
import leaf_png from "../assets/invoice/Vector.png"
import mayi_logo from "../assets/invoice/Mayiiq_Final_Logo 1.png"
import leaf_dwn from "../assets/invoice/leaf-dwn.png"
import leaf_up from "../assets/invoice/leaf-up.png"
import { Image, Text, View, Page, Document, StyleSheet, Font } from '@react-pdf/renderer'
// import poppins from "../../public/fonts/Poppins/Poppins-Regular.ttf"
import poppins_reg from "../fonts/Poppins/Poppins-Regular.ttf"
import poppins_med from "../fonts/Poppins/Poppins-Medium.ttf"
import poppins_SemiBold from "../fonts/Poppins/Poppins-SemiBold.ttf"
import poppins_Bold from "../fonts/Poppins/Poppins-Bold.ttf"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
// import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from "../redux/snackbar"
import CustomAxios from "../utils/CustomAxios"

const hyphenationCallback = (word) => {
    if( word.trim() && word.length>=1){
        // console.log("-----word-------",word.length)

        if(word.split(" ").length>=2){
            // console.log("--------------------",word,word.split(" "))
            return word.split(" ")
        }
        else if(word.length>=20){
            const mid = Math.ceil(word.length / 2);
            const firstHalf = word.substring(0, mid) + '-';
            const secondHalf = word.substring(mid);
            return [firstHalf, secondHalf];
        }
    }
    
    return [word]
}
  
Font.registerHyphenationCallback(hyphenationCallback);


Font.register({
    family: 'Poppins',
    fonts: [
      { src: poppins_reg, fontWeight: 400 },
      { src: poppins_med, fontWeight: 500 },
      { src: poppins_SemiBold, fontWeight: 600 },
      { src: poppins_Bold, fontWeight: 700 }
    ]
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    //   backgroundColor: '#FFFFFF',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  theader: {
    textAlign: "center",
    color: "#1F1F1F",
    fontSize: "11px",
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "4px 12px",
    flex: 1,
    backgroundColor: "rgba(214, 218, 226, 0.32)",
    borderBottom: "0.5px",
    borderColor: "rgba(116, 119, 117, 1)",
  },
  theader2: { textAlign: "left", width: "170px", flex: 2.35 },
  rest_theaders: { width: "69px" },
  tbody: {
    fontSize: "10px",
    padding: "4px 12px",
    flex: 1,
    fontWeight: 400,
    fontFamily: "Poppins",
    color: "#1F1F1F",
    textAlign: "center",
    justifyContent:"center"
  },
  tbody2: { textAlign: "left", width: "170px", flex: 2.35 },
  rest_tbody: { width: "69px" },
  // total:{ fontSize : 9, paddingTop: 4 , paddingLeft: 7 , flex:1.5, borderColor : 'whitesmoke', borderBottomWidth:1},
  sub_total: {
    fontSize: "10px",
    fontFamily: "Poppins",
    fontWeight: 400,
    padding: "4px 12px",
    color: "#1F1F1F",
    flex: 1,
  },
  total: {
    fontSize: "10px",
    fontFamily: "Poppins",
    fontWeight: 600,
    padding: "4px 12px",
    flex: 1,
  },
});

const Invoice = ({ deliveryWaiveLimit,order_details }) => {
    let platform_fee = 1.5/100
    const [isTamilnadu,setIsTamilnadu] = useState(false)
    console.log(order_details)

    useEffect(()=>{
        if(order_details?.shippings?.state?.toLowerCase().replace(" ","") == "tamilnadu"){
            setIsTamilnadu(true)
        }
        else{
            setIsTamilnadu(false)
        }
    },[order_details])
  console.log("----------inv order details---------",order_details)
  // console.log(order_details?.carts?.reduce((sum,p)=> sum + ( parseFloat(p.product.selling_price) * parseFloat(p.product.quantity) )))
  return (
    // <Grid container sx={{width:"595px",height:"842px",position:"relative"}}>
    //     <Grid item>
    //         <Box component="img" src={leaf_png} sx={{width:"157px",height:"142px",position:"absolute",right:0,top:0}}/>
    //     </Grid>
    //     <Stack sx={{position:'fixed',top:"59px",left:"40px",width:"515px",height:"724px"}}>
    //         <Stack sx={{width:"258px",height:"291px",gap:"40px",alignItems:"flex-start"}}>
    //             <Box component="img" src={mayi_logo} sx={{width:"99px",height:"43px"}}/>
    //             <Stack sx={{gap:"30px",width:"258px",height:"208px"}}>
    //                 <Stack sx={{gap:"16px",width:"205px",height:"99px"}}>
    //                     <Typography sx={{fontFamily:"Poppins",fontSize:"26px",fontWeight:500,lineHeight:"39px",color:"#1F1F1F"}}>Tax Invoice</Typography>
    //                     <Stack direction="row" sx={{gap:"8px",width:"193px",justifyContent:"space-between"}}>
    //                         <Typography sx={{fontFamily:"Poppins",fontSize:"12px",fontWeight:400,lineHeight:"18px",color:"#1F1F1F"}}>Invoice Date:</Typography>
    //                         <Typography sx={{fontFamily:"Poppins",fontSize:"12px",fontWeight:400,lineHeight:"18px",color:"#1F1F1F"}}>DD/MM/YYYY</Typography>
    //                     </Stack>
    //                     <Stack direction="row" sx={{gap:"8px",width:"205px",justifyContent:"space-between"}}>
    //                         <Typography sx={{fontFamily:"Poppins",fontSize:"12px",fontWeight:400,lineHeight:"18px",color:"#1F1F1F"}}>Invoice Number:</Typography>
    //                         <Typography sx={{fontFamily:"Poppins",fontSize:"12px",fontWeight:400,lineHeight:"18px",color:"#1F1F1F"}}>XXXXXXXXXXXX</Typography>
    //                     </Stack>
    //                 </Stack>
    //                 <Stack>
    //                     <Typography sx={{fontFamily:"Poppins",fontSize:"14px",fontWeight:400,lineHeight:"21px",color:"#1F1F1F"}}>Customer:</Typography>
    //                     <Stack>
    //                     <Typography sx={{fontSize:"12px",fontWeight:700,color:"#1F1F1F"}}>Manikanta</Typography>
    //                     </Stack>
    //                 </Stack>
    //             </Stack>
    //             <Stack>

    //             </Stack>
    //         </Stack>
    //     </Stack>
    // </Grid>

    // <Document>
    //   <Page
    //     style={{ padding: 10,position: "relative" }}
    //   >

    //     <View style={{position:"absolute",right:0,top:"0px"}}>
    //         <Image style={{width:"157px",height:"142px"}} src={leaf_png}/>
    //     </View>
    //     <View style={{position:"absolute",right:"0px",top:"0px"}}>
    //         <Image style={{width:"502px",height:"454px"}} src={leaf_up}/>
    //     </View>
    //     <View style={{position:"absolute",left:"0px",bottom:"0px",zIndex:2}}>
    //         <Image style={{width:"502px",height:"454px"}} src={leaf_dwn}/>
    //     </View>
        
    //     <View
    //     debug
    //       style={{
    //         position: "fixed",
    //         top: "59px",
    //         left: "40px",
    //         width: "515px",
    //         height: "100%",
    //         backgroundColor: "green",
    //       }}
    //     >
    //     <View>
    //       {Array.from("123456789123456789123456789123456789").map(() =>
    //         order_details?.carts?.map((cart, c_id) => (
    //           <View
    //             key={c_id}
    //             style={[
    //               { flexDirection: "row" },
    //               c_id > 0 &&
    //                 c_id % 2 !== 0 && {
    //                   backgroundColor: "rgba(214, 218, 226, 0.32)",
    //                 },
    //               order_details?.carts?.length === c_id + 1 && {
    //                 borderBottom: "0.5px",
    //                 borderColor: "rgba(116, 119, 117, 1)",
    //               },
    //             ]}
    //           >
    //             <View style={[styles.tbody, styles.tbody2]}>
    //               <Text>{cart?.product?.name}</Text>
    //             </View>
    //             <View style={styles.tbody}>
    //               <Text>{cart?.quantity}</Text>
    //             </View>
    //             <View style={[styles.tbody]}>
    //               <Text>
    //                 {parseFloat(cart?.price)
    //                   .toFixed(2)
    //                   .toLocaleString("en-IN", {
    //                     minimumFractionDigits: 2,
    //                     maximumFractionDigits: 2,
    //                   })}
    //               </Text>
    //             </View>

    //             <View style={styles.tbody}>
    //               <Text>
    //                 {parseFloat(cart?.product?.tax).toLocaleString("en-IN", {
    //                   minimumFractionDigits: 2,
    //                   maximumFractionDigits: 2,
    //                 })}
    //               </Text>
    //             </View>
    //             <View style={[styles.tbody, { textAlign: "right" }]}>
    //               <Text>
    //                 {(
    //                   parseFloat(cart?.price) *
    //                   parseFloat(cart?.quantity)
    //                 ).toLocaleString("en-IN", {
    //                   minimumFractionDigits: 2,
    //                   maximumFractionDigits: 2,
    //                 })}
    //               </Text>
    //             </View>
    //           </View>
    //         ))
    //       )}
    //     </View>
    //     </View>



        


    //   </Page>
    // </Document>

    <Document>
        <Page  size="A4" style={[{position:"relative",fontFamily:"Poppins",paddingBottom:"10px"}]}>
            <View style={{position:"absolute",right:0,top:"0px"}}>
                <Image style={{width:"157px",height:"142px"}} src={leaf_png}/>
            </View>
            <View style={{position:"absolute",right:"0px",top:"0px"}} fixed>
                <Image style={{width:"502px",height:"454px"}} src={leaf_up}/>
            </View>
            
            {/* <View style={{position:"absolute",left:"370px",top:"979px"}}>
                <Image style={{width:"502px",height:"454px"}} src={leaf_dwn}/>
            </View> */}
            <View style={{position:'fixed',top:"59px",left:"40px",justifyContent:"space-between"}}>
                <View style={{gap:"40px",alignItems:"flex-start"}}>
                    <Image style={{height:"43px"}} src={mayi_logo}/>
                    <View style={{gap:"16px"}}>
                        <View style={{height:"99px"}}>
                            <Text style={{fontSize:"26px",fontWeight:500,color:"1F1F1F"}}>Tax Invoice</Text>
                            <View style={{flexDirection:"row",gap:"8px"}}>
                                <Text style={{fontSize:"12px",fontWeight:400,color:"#1F1F1F"}}>Invoice Date:</Text>
                                <Text style={{fontSize:"12px",fontWeight:400,color:"#1F1F1F"}}>{order_details?.created_on && new Date(order_details?.created_on).toLocaleDateString()}</Text>
                            </View>
                            <View style={{flexDirection:"row",gap:"8px"}}>
                                <Text style={{fontSize:"12px",fontWeight:400,color:"#1F1F1F"}}>Invoice Number:</Text>
                                <Text style={{fontSize:"12px",fontWeight:400,color:"#1F1F1F"}}>{order_details?.invoice_details?.invoice_no}</Text>
                            </View>
                        </View>
                        <View style={{gap:"4px"}}>
                            <Text  style={{fontSize:"14px",fontWeight:400,color:"#1F1F1F"}}>Customer:</Text>
                            <View style={{width:"258px"}}>
                                <Text style={{fontSize:"12px",fontWeight:700,color:"#1F1F1F",textTransform:"capitalize"}}>{order_details?.user_details?.first_name + order_details?.user_details?.last_name  || order_details?.user_details?.username }</Text>
                                {/* <Text style={{fontSize:"12px",fontWeight:400,color:"#1F1F1F"}}>{order_details?.self_pickup?"Pickup Address:":"Delivery Address:"}</Text> */}
                                <Text style={{fontSize:"12px",fontWeight:400,color:"#1F1F1F"}}>{order_details?.shippings?.address}</Text>
                                <Text style={{fontSize:"12px",fontWeight:400,color:"#1F1F1F"}}>{order_details?.shippings?.town_city},   {order_details?.shippings?.state}</Text>
                                <Text style={{fontSize:"12px",fontWeight:400,color:"#1F1F1F"}}>{order_details?.shippings?.mobileno}</Text>
                            </View>
                        </View>
                    </View>
                    <View  style={{width:"515px"}}>
                        <View style={{flexDirection:"row"}} >
                            <View style={[styles.theader,styles.theader2]}>
                                <Text>Product</Text>
                            </View>
                            <View style={[styles.theader]}>
                                <Text>Qty</Text>
                            </View>
                            <View style={[styles.theader,{alignItems:"center",justifyContent:"space-between"}]}>
                                <Text>Item price</Text>
                                <View style={{flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                                    {/* <Text style={{fontSize:"6px"}}>Inclusive of </Text> */}
                                        {/* <Text style={{fontSize:"6px"}}> GST</Text>
                                        <Text style={{fontSize:"4px",lineHeight:2}}>1</Text> */}
                                    {/* <View style={[{flexDirection:"row",justifyContent:"center",alignItems:"flex-start"}]}>
                                    </View> */}
                                    {/* <Text style={{fontSize:"6px"}}> (12%)</Text> */}
                                </View>
                            </View>
                            {/* <View style={[styles.theader,{flexDirection:"row",justifyContent:"center"}]}>
                                <Text>GST</Text>
                                <Text style={{fontSize:"8px",lineHeight:2}}>1</Text>
                            </View> */}
                            <View style={[styles.theader]}>
                                <Text style={{textAlign:"right"}}>Amount</Text>
                            </View>
                        </View>
                        <View>
                            {
                                // Array.from("123456789123").map(()=>(

                                    order_details?.carts?.map((cart,c_id)=>(

                                            <View key={c_id} style={[{flexDirection:"row"}, c_id>0 && c_id % 2 !==0 && {backgroundColor:"rgba(214, 218, 226, 0.32)"} ,order_details?.carts?.length === c_id +1 && {borderBottom:"0.5px",borderColor:"rgba(116, 119, 117, 1)"}]}>
                                                <View style={[styles.tbody,styles.tbody2]}>
                                                    <Text>{cart?.product?.name}</Text>
                                                </View>
                                                <View style={styles.tbody}>
                                                    <Text>{cart?.quantity}</Text>
                                                </View>
                                                <View style={[styles.tbody]}>
                                                    <Text>{'₹'+ parseFloat(cart?.price?cart?.price:cart?.product.selling_price).toFixed(2).toLocaleString('en-IN',{minimumFractionDigits:2,maximumFractionDigits:2})}</Text>
                                                </View>

                                                {/* <View style={styles.tbody}>
                                                    <Text>{ parseFloat(cart?.product?.tax).toLocaleString('en-IN',{minimumFractionDigits:2,maximumFractionDigits:2}) }</Text>
                                                </View> */}
                                                <View style={[styles.tbody,{textAlign:"right"}]}>
                                                    <Text>{'₹'+ (parseFloat(cart?.price?cart?.price:cart?.product.selling_price) * parseFloat(cart?.quantity)).toLocaleString('en-IN',{minimumFractionDigits:2,maximumFractionDigits:2}) }</Text>
                                                </View>
                                            </View>
                                    ))
                                // ))
                            }
                        </View>

                        <View style={{flexDirection:"row"}}>
                            <View style={styles.sub_total}></View>
                            <View style={styles.sub_total}></View>
                            <View style={styles.sub_total}></View>
                            <View style={styles.sub_total}>
                                <Text>Sub Total</Text>
                            </View>
                            <View style={[styles.sub_total,{textAlign:"right"}]}>
                                <Text>{'₹'+ (order_details?.carts?.reduce((sum,cart)=> sum + (parseFloat(cart?.price?cart?.price:cart?.product.selling_price) * parseFloat(cart?.quantity)),0))?.toLocaleString('en-IN',{minimumFractionDigits:2,maximumFractionDigits:2}) }</Text>
                            </View>
                        </View>

                        <View style={{flexDirection:"row"}}>
                            <View style={styles.sub_total}></View>
                            {/* <View style={styles.sub_total}></View>
                            <View style={styles.sub_total}></View> */}
                            <View style={[styles.sub_total,{textAlign:"right",flex:2}]}>
                                {isTamilnadu ?<Text>Total GST (12%)</Text>:<Text>Total IGST (12%)</Text>}
                            </View>
                            <View style={[styles.sub_total,{textAlign:"right"}]}>
                                <Text>{'₹'+  parseFloat(order_details?.tax).toFixed(2) }</Text>
                            </View>
                        </View>

                        <View style={{flexDirection:"row"}}>
                            <View style={styles.sub_total}></View>
                            {/* <View style={styles.sub_total}></View> */}
                            {/* <View style={styles.sub_total}></View> */}
                            <View style={[styles.sub_total,{textAlign:"right",flex:2}]}>
                                <Text>Delivery Fees</Text>
                            </View>
                            <View 
                                style={[
                                    styles.sub_total,
                                    { 
                                    textAlign: "right",
                                    textDecoration:order_details?.deliveryBenefits ?"line-through": ""
                                }]}
                                >
                                <Text>
                                    {'₹' + (order_details?.delivery_charge)?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </Text>
                                </View>

                        </View>

                        <View style={{flexDirection:"row"}}>
                            <View style={styles.sub_total}></View>
                            {/* <View style={styles.sub_total}></View>
                            <View style={styles.sub_total}></View> */}
                            <View style={[styles.sub_total,{textAlign:"right",flex:2}]}>
                                <Text>Transaction Fees</Text>
                            </View>
                            <View style={[styles.sub_total,{textAlign:"right"}]}>
                                <Text>{'₹'+ ( parseFloat(order_details?.cash_pickup_id? 0 : order_details?.transaction_fee)
                                // +parseFloat(order_details?.platform_fee)
                                )?.toLocaleString('en-IN',{minimumFractionDigits:2,maximumFractionDigits:2}) 
                                }</Text>
                            </View>
                        </View>
                        {order_details?.discounts>0&&
                        <View style={{flexDirection:"row"}}>
                            <View style={styles.sub_total}></View>
                            {/* <View style={styles.sub_total}></View>
                            <View style={styles.sub_total}></View> */}
                            <View style={[styles.sub_total,{textAlign:"right",flex:2}]}>
                                <Text>Applied Cash Incentives </Text>
                            </View>
                            <View style={[styles.sub_total,{textAlign:"right"}]}>
                                <Text>{'-₹'+ ( parseFloat(order_details?.discounts)
                                // +parseFloat(order_details?.platform_fee)
                                )?.toLocaleString('en-IN',{minimumFractionDigits:2,maximumFractionDigits:2}) 
                                }</Text>
                            </View>
                        </View>}

                        <View style={{flexDirection:"row"}}>
                            <View style={styles.sub_total}></View>
                            {/* <View style={styles.sub_total}></View>
                            <View style={styles.sub_total}></View> */}
                            <View style={[styles.total,{textAlign:"right",flex:2}]}>
                                <Text>Total (INR)</Text>
                            </View>
                            <View style={[styles.total,{textAlign:"right"}]}>
                                <Text>{'₹'+ parseFloat(
                                    order_details?.total_amount
                                    // +
                                    // parseFloat(order_details?.platform_fee))?.toLocaleString('en-IN',{minimumFractionDigits:2,maximumFractionDigits:2}
                                    ).toFixed(2)}
                                </Text>
                            </View>
                        </View>

                        <View>
                            {isTamilnadu && <Text style={[{fontSize:"5px",padding:"4px 12px"}]}>Total GST represents the aggregate value of both CGST (6%) and SGST(6%) components.</Text>}
                        </View>
                        <View  style={{gap:"8px",paddingTop:"20px",alignItems:"center"}}>
                            <View  style={{gap:"2px",alignItems:"center"}}>
                                <Text style={{fontSize:"8px"}}>Billing or service question? Call +91 7845 615 541 or email us at billing@mayiiq.com</Text>
                                <Text style={{fontSize:"8px"}}>MAYI IQ AI Technologies LLP, 340 B4-5, 3rd Floor, Sri Sowdeswari Nagar, Okkiyam, Thoraipakkam, Chennai, Tamilnadu 600097.</Text>
                                <Text style={{fontSize:"8px"}}>GST NO: 33ABWFA3786C1Z8</Text>
                            </View>
                            <Text style={{fontSize:"5px",paddingTop:"12px"}}>Computer-generated invoice do not require signature.</Text>
                        </View>
                    </View>
                </View>
            </View>
            <View style={{position:"absolute",left:"0px",bottom:"0px"}} fixed>
                <Image style={{width:"502px",height:"454px",}} src={leaf_dwn}/>
            </View>
            <View style={{position:"absolute",bottom:5,right:10}} fixed  render={({ pageNumber,totalPages }) => ( totalPages >1 && (
                <Text style={{fontSize:"10px"}}> {pageNumber} of {totalPages} </Text>
            ))} />
        </Page>
    </Document>
  );
};

export default Invoice;
