import { Modal, Box, Typography, InputBase, Button,Grid,Select,MenuItem } from "@mui/material"
import { useSelector } from "react-redux"
import { updateOpenWithdrawRequest ,updateTransactionPinModal,updateSelecteIdForTransaction,updateWithdrawAmount,updateWithdrawAccount,updateOtpCheck,updateWithdraw, updateWithdrawAmountChange} from "../redux/user_reducer"
import { useDispatch } from "react-redux"
import { useMediaQuery } from "react-responsive"
import CloseIcon from '@mui/icons-material/Close';
import { formStyle } from "../modules/profile"
import { useEffect, useState } from "react"
import { Redirection } from "./Redirections"
import { TranslateText } from "../utils/googletranslator"


const WithdrawForm = () => {
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isLaptop = useMediaQuery({ query: '(min-width: 900px)' })
    const open = useSelector(state => state.user.openWithdrawRequest)
    const allBank=useSelector(state=>state.user.allBank)
    const  accumulated=useSelector(state=>state.user.accumulated)
    const  userResponse=useSelector(state=>state.user.member_response)
    const [bankAccount,setBankAccount]=useState()
    const [amount,setAmount]=useState()
    const [errors,setErrors]=useState({})
    const withdrawAmount=useSelector(state=>state.user.withdrawAmount)
    const [existingWithdrawAmount,setExistingWithdrawAmount]=useState(0)
    const withdrawAccount=useSelector(state=>state.user.withdrawAccount)
    const id=useSelector(state=>state.user.id)
    const withdrawAmountChange=useSelector(state=>state.user.withdrawAmountChange)
    const currentAmount=useSelector(state=>state.user.currentAmount)
    const withdrawid=useSelector(state=>state.user.withdrawId)
    const withdrawRequest=useSelector(state=>state.user.withdraRequest)
    // const navigate = useNavigate();
    // console.log(open)
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(updateOpenWithdrawRequest(false))

        dispatch(updateWithdraw(false))
        dispatch(updateWithdrawAmountChange(false))
        // dispatch(updateWithdrawAmount(""))
        // dispatch(updateWithdrawAccount(""))
        setErrors({})
        // setOpen(false);
    }

    useEffect(()=>{
        handleClose()
    },[])

    // const checkWithdrawTimeWindow = () => {
    //     const now = new Date();
    //     const currentDay = now.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
    //     const currentTime = now.getHours() * 60 + now.getMinutes(); // Total minutes since midnight
    
    //     const endTuesday = 10 * 60; // Tuesday 10:00 AM in minutes
    
    //     // Log the day and time for debugging
    //     console.log(`Current Day: ${currentDay}`);
    //     console.log(`Current Time (minutes since midnight): ${currentTime}`);
    //     console.log(`End Tuesday (minutes since midnight): ${endTuesday}`);
    
    //     // Allow withdrawals only from Sunday to Tuesday before 10:00 AM
    //     if (currentDay === 0 || currentDay === 1 || (currentDay === 2 && currentTime <= endTuesday)) {
    //         return true;
    //     }
    
    //     // For any time after Tuesday 10:00 AM, disallow withdrawals
    //     return false;
    // };
    
    // useEffect(() => {
    //     if (open) {
    //         const validTimeWindow = checkWithdrawTimeWindow();
    //         if (!validTimeWindow) {
    //             setErrors(prevErrors => ({ ...prevErrors, time: 'Withdrawals are only allowed from Sunday 12:00 AM to Tuesday 10:00 AM.' }));
    //         }
    //     }
    // }, [open]);
    


 



   
    


    // const checkWithdrawTimeWindow = () => {
    //     const now = new Date();
    //     const currentDay = now.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
    //     const currentTime = now.getHours() * 60 + now.getMinutes(); // Total minutes from start of day
    
    //     const startSunday = 0; // Sunday 12:00 AM (in minutes)
    //     const endThursday = 13 * 60; // Thursday 2:00 PM (in minutes)


    //     console.log(`Current Day: ${currentDay}`);
    //     console.log(`Current Time (minutes since midnight): ${currentTime}`);
    //     console.log(`End Thursday (minutes since midnight): ${endThursday}`);
    
    //     // Check if it's between Sunday 12:00 AM and Thursday 2:00 PM
    //     if (currentDay === 0 || (currentDay >= 1 && currentDay <= 3) || (currentDay === 4 && currentTime <= endThursday)) {
    //         return true;
    //     }
    
    //     return false;
    // }

    
    
    

    // useEffect(() => {
    //     if (open) {
    //         const validTimeWindow = checkWithdrawTimeWindow();
    //         if (!validTimeWindow) {
    //             setErrors(prevErrors => ({ ...prevErrors, time: 'Withdrawals are only allowed from Sunday 12:00 AM to Thursday 01:00 PM.' }));
    //         }
    //     }
    // }, [open]);

    

    const handleSubmit=()=>{

        // const validTimeWindow = checkWithdrawTimeWindow(); // Implement the time check logic
        // if (!validTimeWindow) {
        //     setErrors(prevErrors => ({...prevErrors, time: 'Withdrawals are only allowed from Sunday 12:00 AM to Thursday 2:00 PM.'}));
        //     return; // Stop the form submission
        // }

        const valid=validation()
        if (valid){
            // dispatch(updateWithdrawAccount(bankAccount))
            // dispatch(updateWithdrawAmount(amount))
            dispatch(updateSelecteIdForTransaction(id))
            dispatch(updateTransactionPinModal(true))
            dispatch(updateOpenWithdrawRequest(false))
            // dispatch(updateOtpCheck('withdrawal'));
            // navigate("/otp-verification");
        }


    }

useEffect(()=>{
    // console.log("???????????????????????????","existing withdraw amount",)
    // console.log(withdrawRequest)
setExistingWithdrawAmount(withdrawAmount)
},[withdrawAmount])
    const validation=()=>{
        // console.log(withdrawRequest,withdrawid)
        const [currentValue]=withdrawRequest.filter(item=>item.id==withdrawid)
        // console.log(currentValue)
        let newErrors={}
        let valid=true
        if (!withdrawAccount) {
            newErrors.withdrawAccount = 'Please Enter Your Withdraw Account';
            valid = false;
        }
        if (!withdrawAmount.toString()?.trim()) {
            newErrors.amount = 'Please Select Your Amount';
            valid=false
        }
        else if(withdrawAmount <= 0){
            newErrors.amount = 'Enter Valid Amount';
            valid=false
        }
        else if(!withdrawAmountChange){
            if(withdrawAmount>userResponse?.accumulated.withdrawal_available){
                newErrors.amount = 'Insufficient Withdraw Balance Amount';
                valid=false
            }
        }
        else if(withdrawAmountChange){
            if(withdrawAmount>userResponse?.accumulated.withdrawal_available+currentValue.without_deduction){
                newErrors.amount = 'Insufficient Withdraw Balance Amount';
                valid=false
            }
            
        }
        setErrors(newErrors)
          return valid
    }
    // console.log("errors",errors.time,open)

    const handleAmountChange = (event) => {
        const value = event.target.value;
        // setExistingWithdrawAmount(withdrawAmount)
        let newErrors={}
        const regex = /^[0-9]*[.,]?[0-9]{0,2}$/;
    
        if (regex.test(value)) {
          dispatch(updateWithdrawAmount(value))
          newErrors.amount=''
          setErrors(newErrors);
        } else {
            newErrors.amount = 'Please enter valid amount';
            setErrors(newErrors) 
        }
      };

    return (<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{ zIndex: "21 !important" }}
    > 
      {errors?.time=='Withdrawals are only allowed from Sunday 12:00 AM to Thursday 01:00 PM.' ? 
        <Box sx={{ width: isMobile ? "calc(100% - 64px)" : "400px", background: "#fff", left: "50%", top: "50%", position: "absolute", transform: 'translate(-50%, -50%)', padding: "32px", border: "none", overflowY: "scrollY" }}>
            <TranslateText sx={{ fontWeight: "600", lineHeight: "24px", color: "error.light" }}>
                {errors.time}
            </TranslateText>
        </Box>
    : (
        <>


       {(((userResponse.account?.role=="user" || userResponse.account?.role=="sc") && !userResponse?.account?.kyc_status ) || (userResponse?.bankdetails?.length==0))? <Box sx={{ width: isMobile ? "calc(100% - 64px)" : "613px", background: "#fff", left: "50%", top: "50%", position: "absolute", transform: 'translate(-50%, -50%)',  border: "none", overflowY: "scrollY" }}>
        <Redirection 
               headings={(() => {
                const messages = [
                   (userResponse?.bankdetails?.length==0)&& "Update Bank Account", 
                    ((userResponse.account?.role=="user" || userResponse.account?.role=="sc") && !userResponse?.account?.kyc_status) && "KYC details"
                ].filter(Boolean);
        
                return <TranslateText>{messages.length > 1 
                    ? messages.join(" and ") 
                    : ((userResponse.account?.role=="user" || userResponse.account?.role=="sc") && !userResponse?.account?.kyc_status)?`Update ${messages[0]}`:messages[0]}</TranslateText>
            })()}
            navigation= {((userResponse.account?.role=="user" || userResponse.account?.role=="sc") && !userResponse?.account?.kyc_status)?"/profile#kyc-details":(userResponse?.bankdetails?.length==0)?"/profile#bankDetails":"/profile#kyc-details"}
            content={(() => {
                const messages = [
                   (userResponse?.bankdetails?.length==0)&& "Please update Bank Account details", 
                    ((userResponse.account?.role=="user" || userResponse.account?.role=="sc") && !userResponse?.account?.kyc_status) && " KYC details"
                ].filter(Boolean);
        
                return <TranslateText>{messages.length > 1 
                    ? messages.join(" and ") 
                    : ((userResponse.account?.role=="user" || userResponse.account?.role=="sc")&& !userResponse?.account?.kyc_status)?`Please Update ${messages[0]}`:messages[0]}</TranslateText>
            })()}
            button={(() => {
                const messages = [
                   (userResponse?.bankdetails?.length==0)&& "Update Bank Account", 
                    ((userResponse.account?.role=="user" || userResponse.account?.role=="sc") && !userResponse?.account?.kyc_status) && "KYC details"
                ].filter(Boolean);
        
                return <TranslateText> {messages.length > 1 
                    ? messages.join(" and ") 
                    : ((userResponse.account?.role=="user" || userResponse.account?.role=="sc") && !userResponse?.account?.kyc_status)?`Update ${messages[0]}`:messages[0]}</TranslateText>;
            })()}
        />
       </Box>:!userResponse?.account?.transaction_pin?(<Box sx={{ width: isMobile ? "calc(100% - 64px)" : "613px", background: "#fff", left: "50%", top: "50%", position: "absolute", transform: 'translate(-50%, -50%)',  border: "none", overflowY: "scrollY" }}>
                        <Redirection
                            headings={<TranslateText>Transaction pin</TranslateText>}
                            navigation="/profile#transaction_pin"
                            content={<TranslateText>For your security, you need to set up a Transaction PIN to proceed with transferring funds. Please create your Transaction PIN now to ensure your transaction is protected.</TranslateText>}
                            button={<TranslateText>Setup transaction pin</TranslateText>}
                        />
                    </Box> )
                   :<Box sx={{ width: isMobile ? "calc(100% - 64px)" : "400px", background: "#fff", left: "50%", top: "50%", position: "absolute", transform: 'translate(-50%, -50%)', padding: "24px", border: "none", overflowY: "scrollY",display:"flex",flexDirection:"column",gap:"16px" }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <TranslateText sx={{ fontWeight: "600", fontSize: "16px", lineHeight: "24px", letterSpacing: "0.15px", color: "primary.light" }}>Withdraw Request</TranslateText>
                <CloseIcon sx={{ fontWeight: "600", lineHeight: "24px",cursor:"pointer" }} onClick={handleClose} />
            </Box>
            <Box sx={{display:"flex",flexDirection:"column",gap:"16px"}}> 
                <Box sx={{display:"flex",flexDirection:"column",gap:"8px",width:"100%"}}>
                    <TranslateText sx={{ fontWeight: "600", fontSize: "16px", lineHeight: "24px", letterSpacing: "0.15px", color: "black" }}>Amount <span component="span" sx={{ color: "#EF4444" }}>*</span></TranslateText>
                    <InputBase type="tel" onChange={handleAmountChange} value={withdrawAmount} sx={{width: "100%", height: "36px", borderRadius: "4px", gap: "8px", border: "1px solid #CBD5E1", padding: "8px 12px 8px 12px" }} placeholder="Eg: 250" />
                    {errors.amount && <TranslateText sx={{ color: "error.light" }}>{errors.amount}</TranslateText>}
                </Box>
                <Box sx={{display:"flex",flexDirection:"column",gap:"8px",width:"100%"}}>
                    <TranslateText sx={{ fontWeight: "600", fontSize: "16px", lineHeight: "24px", letterSpacing: "0.15px", color: "black" }}>Select Bank <span component="span" sx={{ color: "#EF4444" }}>*</span></TranslateText>
                    <Select
        //   labelId="demo-simple-select-label"
        //   id="demo-simple-select"
          // value={age}
        //   label="Select Bank Account"
        sx={{width: "100%", height: "36px", borderRadius: "4px", gap: "8px", border: "1px solid #CBD5E1", padding: "8px 0px 8px 0px",outline:"none" }}
          displayEmpty
          onChange={e=>dispatch(updateWithdrawAccount(e.target.value))}
          inputProps={{ 'aria-label': 'Without label' }}
          value={withdrawAccount}
          defaultValue={withdrawAccount&&withdrawAccount}
        >
            <MenuItem value="" disabled sx={{fontFamily: "inter", fontSize: "16px", fontStyle: "poppins", fontWeight: "400", lineHeight: "24px",color:"grey"}}>Select Bank Account</MenuItem>

           {allBank?.map(item => {
          return<MenuItem value={item.id} sx={{textTransform:"capitalize"}}>{item.bank_name}</MenuItem>
           })}
        </Select>
        {errors.withdrawAccount && <TranslateText sx={{ color: "error.light" }}>{errors.withdrawAccount}</TranslateText>}
                    {/* {allBank?.map(item => {
            return <Grid xs={12} md={4} sm={6} item sx={{ width: { xs: "100%", sm: "100%", md: "100%", padding: "10px",cursor:"pointer" } }} ><Box sm={3} item sx={{ border: "1px solid", padding: "12px", borderColor: "grey.500", borderRadius: "10px" }}>
                {/* <TranslateText sx={{ fontWeight: "600",textTransform:"capitalize" }}>{item.address_type}</TranslateText>  
                <TranslateText sx={{ fontWeight: "600", textTransform:"capitalize"}}>{item.account_name}</TranslateText>
                <TranslateText sx={{ wordBreak: "break-all" }}>Account Number: {item.account_number}</TranslateText>
                <TranslateText sx={{ whiteSpace: "pre-wrap",textTransform:"capitalize" }}>IFSC Code: {item.ifsc_code.toUpperCase()}</TranslateText>
                <TranslateText > Bank Name: {item.bank_name.toUpperCase()} </TranslateText>

            </Box>
            </Grid>

        })}  */}
                    {/* // {errors.confirmAcc_number && <TranslateText sx={{ color: "error.light" }}>{errors.confirmAcc_number}</TranslateText>} */}
                </Box>
                <TranslateText sx={{ fontWeight: "400", fontSize: "11px", lineHeight: "16px", letterSpacing: "0.15px", color: "black",bgcolor:"grey.200" }}><TranslateText sx={{ fontWeight: "700", fontSize: "11px", lineHeight: "16px", letterSpacing: "0.15px", color: "black",display:"inline-block" }}>Note:</TranslateText> Transaction fee of 2.31% will be applied to the total value.</TranslateText>
                <Button variant="contained" sx={{height:"48px",width:"100%",borderRadius:"4px",padding:"12px",textTransform:"none"}} onClick={handleSubmit}>{ withdrawAmount>0 ?<><TranslateText>Withdraw</TranslateText> {withdrawAmount>0&& <Typography>{" ₹ " + parseFloat(withdrawAmount-(withdrawAmount*0.0231)).toFixed(2).toString()}</Typography>} </>:<TranslateText>Continue</TranslateText>}</Button>
            </Box>


        </Box>}
        </>)}
    </Modal>)
}
export default WithdrawForm