
import { Box, Divider, Typography, Paper, InputBase, Button, RadioGroup, FormControlLabel, Radio, Grid, Chip, Stack, Skeleton, MenuItem, Select, Avatar, IconButton, Breadcrumbs, Link, InputAdornment, } from "@mui/material"
import { formStyle } from "../modules/profile";
import { Editor } from "primereact/editor";
// import { styles } from "./cards"
import { useEffect, useRef, useState } from "react";
import BackupIcon from '@mui/icons-material/Backup';
import { UseSelector, useDispatch, useSelector } from "react-redux";
import { updateProduct } from "../redux/products";
import axios from "axios";
import CustomAxios from '../utils/CustomAxios';
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from "../redux/snackbar";
import MessageBar from "../modules/messageBar";
import { useNavigate } from "react-router-dom";
import { Add, Close } from "@mui/icons-material";
import { jwtDecode } from "jwt-decode";
import CircularProgress from '@mui/material/CircularProgress';
import { TranslateText } from "../utils/googletranslator";
export const Styles = {
    header: { fontSize: "18px",lineHeight:"28px",fontWeight:500 },
    subheader: { fontSize: "14px", color: "#000000",fontWeight:600, lineHeight:"20px" }
}



const ProductForm = () => {
    const admin_details = jwtDecode(localStorage.getItem('authtoken'))
    // // console.log("-----------------------sdmin",admin_details)
    const product = useSelector(state => state.product?.product)
    const all_product = useSelector(state => state.product?.Allproducts)
    const [isloading, setIsloading] = useState(false)
    const [spefic_prod, setSpecific_prod] = useState('')
    const dispatch = useDispatch()
    const [errors, setErrors] = useState({})

    const fileRef = useRef()
    const GfileRef = useRef()
    const navigate = useNavigate()

    const productCategory = useSelector(state => state.product?.productCategory)
    const EditorQuillRef = useRef()

    const handleProduct = async (status) => {
        const formData = new FormData()
        // // console.log(product.cover)
        if (product.cover) {
            formData.append('file', product.cover)
        }
        if (product.gallery?.length >= 1) {

            for (let i = 0; i < product.gallery.length; i++) {
                if(product.cover !== product.gallery[i]){
                    formData.append('files', product.gallery[i]);
                }
            }
        }

        Object.entries({
            "name": product.name,
            "user_id": admin_details.id,
            "description": product.description,
            "available_quantity": parseInt(product.quantity) || 0,
            "price": parseInt(product.price),
            "selling_price": parseInt(product.sellingPrice),
            "tax": parseInt(product.tax),
            "product_type": "simple",
            "category":product.category,
            "unit": product.unit,
            "slug": product.name?.split(" ").join("-"),
            "sku": product.sku,
            "status": status,
            "details":product.details,
            "length":parseFloat(product.length),
            "width":parseFloat(product.width),
            "height":parseFloat(product.height)

        }).forEach(([key, value]) => {
            formData.append(key, value);
        });

        // // console.log(product)
        const validate = validation(product)
        // console.log(validate,product.cover,product.gallery)

        if(validate){
            setIsloading(true)
            await CustomAxios.post(`products/`, formData).then((res) => {
                setIsloading(false)
                let data = res.data
                if (data.id) {
                    dispatch(updateProduct({}))
                    navigate('/product')
                    dispatch(updateSnackBarMessage("Product Created Sucessfully"))
                    dispatch(updateSnackBarSeverity("success"))
                }
                else if (data.error) {
                    console.error(data.error)
                    if(data.error.search("Uploading Media")){
                        dispatch(updateSnackBarMessage(data.error))
                        dispatch(updateSnackBarSeverity("error"))
                    }
                    else{
                        dispatch(updateSnackBarMessage("Failed"))
                        dispatch(updateSnackBarSeverity("error"))
                    }
                }
            }).catch(err =>{
                console.error(err)
                dispatch(updateSnackBarMessage("Something Went Wrong."))
            })
            setIsloading(false)
            dispatch(updateSnackBarOpen(true))
        }



    }
    const validation = (product) => {
        const newError = {}
        let valid = true

        // // console.log(product)
        if (!product.name?.trim()) {
            // // console.log("it s happening")
            newError.name = "Please enter product name here"
            valid = false

        }

        if (!product.unit?.trim()) {
            // // console.log("it s happening")
            newError.unit = "Please enter unit here"
            valid = false

        }
        if (!product.price) {
            // // console.log("it s happening")
            newError.price = "Please enter price here"
            valid = false

        }
        if(isNaN(parseFloat(product.price))){
            newError.sellingPrice = "Please enter valid price"
            valid = false
        }
        if (!product.sellingPrice) {
            // // console.log("it s happening")
            newError.sellingPrice = "Please enter selling price here"
            valid = false

        }
        if(isNaN(parseFloat(product.sellingPrice))){
            newError.sellingPrice = "Please enter valid selling price"
            valid = false
        }
        if(parseFloat(product.sellingPrice) > parseFloat(product.price)){
            newError.sellingPrice = "Selling price must be less than price"
            valid = false
        }

        
        // if (!product.quantity) {
        //     // // console.log("it s happening")
        //     newError.quantity = "Please enter quantity here"
        //     valid = false
            
        // }
        // if(isNaN(parseFloat(product.quantity))){
        //     newError.tax = "Please enter valid quantity"
        //     valid = false
        // }
        if (!product.sku?.trim()){
            // // console.log("it s happening")
            newError.sku="Please enter SKU here"
            valid = false
        }
        if (!EditorQuillRef.current?.getQuill()?.root?.innerText?.trim()){
            // // console.log("it s happening")
            newError.description="Please enter description here"
            valid = false
        }
        if(!product.details?.trim()){
            newError.details="Please enter brief here"
            valid = false
        }

        // }
        
        if (!product.tax?.trim()) {
            // // console.log("it s happening")
            newError.tax = "Please enter tax here"
            valid = false
            
        }
        if(isNaN(parseFloat(product.tax))){
            newError.tax = "Please enter valid tax"
            valid = false
        }
        // if (!product.status?.trim()) {
        //     // // console.log("it s happening")
        //     newError.status = "Please enter status here"
        //     valid = false

        // }

        if(!product['length'] || isNaN(parseFloat(product['length']))){
            newError.length = "Please enter a valid length"
            valid = false
        }
        if(!product.width || isNaN(parseFloat(product.width))){
            newError.width = "Please enter a valid width"
            valid = false
        }
        if(!product.height || isNaN(parseFloat(product.height))){
            newError.height = "Please enter a valid height"
            valid = false
        }

        if(!product.cover){
            newError.cover = "Please select a thumbnail image."
            valid = false
        }
        if(!product.gallery || (product.gallery && product.gallery.length<1) ){
            newError.gallery = "Please select a gallery image."
            valid = false
        }

        if(!product.category){
            newError.category = "Please select product category."
            valid = false   
        }

        // console.log(product,newError)
        setErrors(newError)

        return valid



    }

    const handleFileCheck = (file,files)=>{
        // // console.log("file check",file && file.type)
        if(file && file.type !== "" && file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg'){
            // // console.log("------coming-------")
            dispatch(updateProduct({ ...product, cover: file }))
        }
        if(files?.length>=1){
            // // console.log(files)
            let values = files.filter((file)=> file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg')
            return values.length>=1?false:true
        }

    }

    const handleGalleryupdate = (e,f_id)=>{
        // console.log("-----in handleGalleryupdate--------")
        e.preventDefault()
        let existing_files_name = product?.gallery ? product?.gallery?.map((file)=>{return file.name}) :""
        let files = Array.from(e?.target?.files || [])
        // console.log("------------0.1----------",existing_files_name,files)
        if(existing_files_name.length>=1 && e.target.files){
            let comb_files = files.filter(file=>{
                if( !existing_files_name.includes(file.name) ){
                    return file
                }
            })
            // console.log("----------------",comb_files)
            dispatch(updateProduct({...product,gallery:[...product.gallery,...comb_files]}))
        }
        else if(e.target.files){
            if(handleFileCheck(false,files)){
                dispatch(updateProduct({...product,gallery:files}))
            }
        }
        else{
            let dummy_details = [...product.gallery]
            // if(dummy_details[f_id].name === product.cover.name){
            //     dummy_details.splice(f_id,1)
            //     dispatch(updateProduct({...product, cover: null, gallery: dummy_details}))
            // }
            // else{
            dummy_details.splice(f_id,1)
            dispatch(updateProduct({...product,gallery:dummy_details}))
            // }
        }
    }

    return (<Box sx={{ padding: "48px 32px ", width: "100%", display: "flex", gap: "32px", flexDirection: "column",  overflowY: "scroll",bgcolor:"#FFF" }}>
        <MessageBar />
        <Stack gap={"16px"}>


            <TranslateText sx={Styles.header}>
                Inventory Management
            </TranslateText>
            {/* <Divider  sx={{marginTop:"32px",border:"1px dashed grey"}}/> */}
            <Breadcrumbs separator=">" sx={{pr:"8px"}}>
                <Box color="inherit" sx={{fontSize:"14px",fontWeight:400,lineHeight:"20px",cursor:"pointer"}} onClick={()=>{navigate('/product')}}>Products</Box>
                <TranslateText sx={{color:"#1099F4",fontWeight:700,fontSize:"14px",lineHeight:"20px"}}>Create Product</TranslateText>
            </Breadcrumbs>
        </Stack>
        <Stack className="main-section" gap={"36px"}>
            <Stack className="sec1" gap="7px">
                <TranslateText sx={[Styles.subheader, { alignSelf: "start" }]}> Product Details </TranslateText>
                <Stack direction={{xs:"column",md:"row"}} sx={{gap:{xs:"36px",lg:"52px"}}}>
                    <Stack sx={{width:{md:"50%"},gap:"20px"}}>
                        <Box>
                            <TranslateText sx={formStyle.label} color="grey.700" >Name <span component="span" color="error">*</span></TranslateText>
                            <InputBase sx={formStyle.inputBase} value={product.name} onChange={(e) => dispatch(updateProduct({ ...product, name: e.target.value }))} />
                            {errors.name && <TranslateText sx={{ color: "error.light" }}>{errors.name}</TranslateText>}
                        </Box>
                        <Box>
                            <TranslateText sx={formStyle.label} color="grey.700">Slug Name</TranslateText>
                            <InputBase sx={formStyle.inputBase} disabled value={product.slugname} onChange={(e) => dispatch(updateProduct({ ...product, slugname: e.target.value }))} />
                        </Box>
                        <Box>
                            <TranslateText sx={formStyle.label} color="grey.700">Unit / Capacity <span component="span" color="error">*</span></TranslateText>
                            <InputBase sx={formStyle.inputBase} value={product.unit} onChange={(e) => dispatch(updateProduct({ ...product, unit: e.target.value }))} />
                            {errors.unit && <TranslateText sx={{ color: "error.light" }}>{errors.unit}</TranslateText>}
                        </Box>
                        <Box>
                            <TranslateText sx={formStyle.label} color="grey.700">Category <span component="span" color="error">*</span></TranslateText>
                            <Select placeholder="Selece Product" fullWidth value={product.category} onChange={(e)=> dispatch(updateProduct({ ...product, category: e.target.value })) }>
                                <MenuItem value={null}>Select product type</MenuItem>
                                {
                                    productCategory?.map((type,type_id)=>{
                                        return(
                                            <MenuItem key={type_id} value={type} sx={{textTransform:"capitalize"}}>{type}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            {errors.category && <TranslateText sx={{ color: "error.light" }}>{errors.category}</TranslateText>}
                        </Box>
                        <Box>
                            <TranslateText sx={formStyle.label} color="grey.700">Brief <span component="span" color="error">*</span></TranslateText>

                            <InputBase multiline rows={2} sx={formStyle.textBox} value={product.details} onChange={(e) => dispatch(updateProduct({ ...product, details: e.target.value }))} />

                            {errors.details && <TranslateText sx={{color:"error.light"}}>{errors.details}</TranslateText>}
                        </Box>
                    </Stack>
                    <Stack sx={{width:{md:"50%"}}}>
                        <Box>
                            <TranslateText sx={formStyle.label} color="grey.700">Description <span component="span" color="error">*</span></TranslateText>  

                            <Editor ref={EditorQuillRef}  style={{...formStyle.textBox,borderColor:"#CBD5E1",height:"437px",width:"100%",borderTop:"none",borderTopRightRadius:0,borderTopLeftRadius:0}}  value={product.description} 
                            onTextChange={(e) =>{
                                dispatch(updateProduct({ ...product, description: e.htmlValue }))
                            }} 
                            />

                            {errors.description && <TranslateText sx={{color:"error.light"}}>{errors.description}</TranslateText>}
                        </Box>
                    </Stack>
                </Stack>
            </Stack>
            <Stack className="sec2" gap={"7px"}>
                <TranslateText sx={[Styles.subheader, { alignSelf: "start" }]}> Product Specification & Pricing </TranslateText>
                <Stack direction={{xs:"column",md:"row"}} sx={{gap:{xs:"36px",lg:"52px"}}}>
                    <Stack sx={{width:{md:"50%"},gap:"20px"}}>
                        <Box>
                            <TranslateText sx={formStyle.label} color="grey.700">SKU <span component="span" color="error">*</span></TranslateText>
                            <InputBase sx={formStyle.inputBase} value={product.sku} onChange={(e) => dispatch(updateProduct({ ...product, sku: e.target.value }))} />
                            {errors.sku && <TranslateText sx={{color:"error.light"}}>{errors.sku}</TranslateText>}
                        </Box>
                        <Stack direction={{xs:"column",md:"row"}} gap={"24px"} sx={{justifyContent:"space-between",alignItems:"center"}}>
                            <Box sx={{width:"100%"}}>
                                <TranslateText sx={formStyle.label} color="grey.700">Price <span component="span" color="error">*</span></TranslateText>
                                <InputBase sx={formStyle.inputBase} value={product.price} onChange={(e) => dispatch(updateProduct({ ...product, price: e.target.value.replace(/\D/g, '') }))} />
                                {errors.price && <TranslateText sx={{ color: "error.light" }}>{errors.price}</TranslateText>}
                            </Box>
                            <Box sx={{width:"100%"}}>
                                <TranslateText sx={formStyle.label} color="grey.700">Sale Price <span component="span" color="error">*</span></TranslateText>
                                <InputBase sx={formStyle.inputBase} value={product.sellingPrice} onChange={(e) => dispatch(updateProduct({ ...product, sellingPrice: e.target.value.replace(/\D/g, '') }))} />
                                {errors.sellingPrice && <TranslateText sx={{ color: "error.light" }}>{errors.sellingPrice}</TranslateText>}
                            </Box>
                        </Stack>
                        <Box>
                            <TranslateText sx={formStyle.label} color="grey.700">Tax <span component="span" color="error">*</span></TranslateText>
                            <InputBase sx={formStyle.inputBase} value={product.tax} onChange={(e) => dispatch(updateProduct({ ...product, tax: e.target.value.replace(/\D/g, '') }))} endAdornment={<TranslateText sx={{color:"grey.500"}}>%</TranslateText>}/>
                            {errors.tax && <TranslateText sx={{ color: "error.light" }}>{errors.tax}</TranslateText>}
                        </Box>

                    </Stack>
                    <Stack sx={{width:{md:"50%"},gap:"20px"}}>
                        <Box>
                            <TranslateText sx={formStyle.label} color="grey.700">Width <span component="span" color="error">*</span></TranslateText>
                            <InputBase required sx={formStyle.inputBase} value={product.width} onChange={(e) => dispatch(updateProduct({ ...product, width: e.target.value.replace(/\D/g, '') }))} endAdornment={<TranslateText sx={{color:"grey.500"}}>cm</TranslateText>}/>
                            {errors.width && <TranslateText sx={{color:"error.light"}}>{errors.width}</TranslateText>}
                        </Box>
                        <Box>
                            <TranslateText sx={formStyle.label} color="grey.700">Height <span component="span" color="error">*</span></TranslateText>
                            <InputBase required sx={formStyle.inputBase} value={product.height} onChange={(e) => dispatch(updateProduct({ ...product, height: e.target.value.replace(/\D/g, '') }))} endAdornment={<TranslateText sx={{color:"grey.500"}}>cm</TranslateText>}/>
                            {errors.height && <TranslateText sx={{color:"error.light"}}>{errors.height}</TranslateText>}
                        </Box>
                        <Box>
                            <TranslateText sx={formStyle.label} color="grey.700">Length <span component="span" color="error">*</span></TranslateText>
                            <InputBase required sx={formStyle.inputBase} value={product.length} onChange={(e) => dispatch(updateProduct({ ...product, length: e.target.value.replace(/\D/g, '') }))} endAdornment={<TranslateText sx={{color:"grey.500"}}>cm</TranslateText>}/>
                            {errors.length && <TranslateText sx={{color:"error.light"}}>{errors.length}</TranslateText>}
                        </Box>
                    </Stack>
                </Stack>
            </Stack>
            <Stack className="sec3" gap={"36px"}>
                <Stack gap={"7px"}>
                    <TranslateText sx={[Styles.subheader, { alignSelf: "start" }]}> Cover / Thumbnail </TranslateText>
                    <Grid container direction="row" sx={{ gap: "1rem", pt: "20px" }}>
                        {!product?.cover ?<Stack onClick={() => { fileRef.current && fileRef.current.click() }} sx={{width:"160px",height:"150px",justifyContent:"center",alignItems:"center",border: "1px solid #D6DAE2",bgcolor:"#EDF1F9",cursor:"pointer",borderRadius:"10px"}}>
                            <Add></Add>
                            <input ref={fileRef} accept=".png, .jpg, .jpeg" type="file" onChange={(e) => { handleFileCheck(e.target.files[0],null);}} style={{ display: "none" }} />
                        </Stack>:
                            <Grid item sx={{position:"relative","&:hover .cover-overlay":{display:"flex"}}}>
                                <img src={URL.createObjectURL(product?.cover)} style={{width:"160px",height:"150px",padding:"8px",border:"1px solid #DBE3F0",borderRadius:"10px"}}/>
                                <Avatar className="cover-overlay" sx={{display:"none",position:"absolute",top:0,right:0,cursor:"pointer",width:24,height:24,"&:hover":{bgcolor:"#1099F4"}}}><Close  color="#DBE3F0" sx={{fontSize:"14px"}} onClick={(e)=> dispatch(updateProduct({ ...product, cover: null })) }  /></Avatar>
                            </Grid>
                        }
                    </Grid>
                    {errors.cover && <TranslateText sx={{ color: "error.light" }}>{errors.cover}</TranslateText>}
                </Stack>
                <Stack gap={"7px"}>
                    <TranslateText sx={[Styles.subheader, { alignSelf: "start" }]}> Gallery </TranslateText>
                    <Grid container direction="row" sx={{ gap: "1rem", pt: "20px" }}>
                        <Stack onClick={() => { GfileRef.current && GfileRef.current.click() }} sx={{width:"160px",height:"150px",justifyContent:"center",alignItems:"center",border: "1px solid #D6DAE2",bgcolor:"#EDF1F9",cursor:"pointer",borderRadius:"10px"}}>
                            <Add></Add>
                            <input ref={GfileRef} multiple={true} accept=".png, .jpg, .jpeg" type="file" onChange={(e) => { handleGalleryupdate(e,null);}} style={{ display: "none" }} />
                        </Stack>
                        {
                            product.gallery?.length>=1 && product.gallery.map((file, f_id) => {
                                return <Grid item key={f_id}>
                                    {
                                        <Stack gap={"7px"} sx={{position:"relative","&:hover .gallery-overlay":{display:"flex"}}}>
                                            <Box component={'img'} src={URL.createObjectURL(file)} sx={{width:"160px",height:"150px",padding:"8px",border:"1px solid #DBE3F0",borderRadius:"10px"}}/>
                                            <Avatar className="gallery-overlay" sx={{display:"none",zIndex:2,position:"absolute",top:0,right:0,cursor:"pointer",width:24,height:24,"&:hover":{bgcolor:"#1099F4"}}}><Close  color="#DBE3F0" sx={{fontSize:"14px"}} onClick={(e)=>handleGalleryupdate(e,f_id)}  /></Avatar>
                                            
                                        </Stack>
                                        
                                    }
                                </Grid>
                            })
                        }
                    </Grid>
                    {errors.gallery && <TranslateText sx={{ color: "error.light" }}>{errors.gallery}</TranslateText>}

                </Stack>
            </Stack>
        </Stack>

        <Box sx={{bgcolor:"#F8F9FF",p:"20px 24px 20px 24px"}}>
            {
                isloading?
                    <Stack direction={"row"} sx={{gap:"24px",justifyContent:"flex-end"}}>
                        <Skeleton variant="rounded" width={80} height={37}>
                            <Button variant="contained" sx={{textTransform:"capitalize"}}>Publish</Button>
                        </Skeleton>
                        <Skeleton variant="rounded" width={105} height={37}>
                            <Button variant="outlined" sx={{textTransform:"capitalize"}}>Save Draft</Button>
                        </Skeleton>
                    </Stack>
                :
                    <Stack direction={"row"} sx={{gap:"24px",justifyContent:"flex-end"}}>
                        <Button variant="contained" sx={{textTransform:"capitalize"}} onClick={()=>handleProduct('publish')}>Publish </Button>
                        <Button variant="outlined" sx={{textTransform:"capitalize"}}  onClick={()=>handleProduct('draft')}>Save Draft</Button>
                    </Stack>
            }
        </Box>


        {/*---------------old code--------------*/}
        <Stack sx={{display:"none"}}>
            <Box sx={{ borderBottom: "1px dashed grey ", paddingBottom: "45px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>


                <TranslateText sx={[Styles.subheader, { alignSelf: "start" }]}>
                    Feature Image
                </TranslateText>

                <Paper sx={{ padding: "32px", width: "60%" }}>
                    <input ref={fileRef} type="file" accept=".png, .jpg, .jpeg" onChange={(e) => { handleFileCheck(e.target.files[0],false) }} style={{ display: "none" }} />
                    <Box
                        onClick={() => { fileRef.current && fileRef.current.click() }}
                        // onDrop={handleDrop}
                        // onDragOver={handleDragOver}
                        sx={{ display: "flex", flexDirection: 'column', gap: "16px", border: "1px dashed grey", height: "150px", justifyContent: "center", alignItems: "center" }}>

                        <BackupIcon sx={{ color: "grey.500", fontSize: "32px", }} />
                        <TranslateText sx={{ color: "primary.dark" }} >Upload an Image</TranslateText>
                        <TranslateText sx={{ color: "grey.800" }} >PNG,JPG</TranslateText>

                    </Box>
                    <Grid container direction="row" sx={{ gap: "1rem", pt: "20px" }}>
                        {
                            product?.cover?.name && <Grid item >
                                <Chip label={product?.cover?.name} deleteIcon={<Close />} onDelete={() => dispatch(updateProduct({ ...product, cover: null }))} />
                            </Grid>
                        }
                    </Grid>
                </Paper>
                {/* <Divider  sx={{marginTop:"32px",border:"1px dashed grey"}}/> */}
            </Box>
            <Box sx={{ borderBottom: "1px dashed grey ", paddingBottom: "45px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>


                <TranslateText sx={[Styles.subheader, { alignSelf: "start" }]}>
                    Gallery
                </TranslateText>
                <Paper sx={{ padding: "32px", width: "60%" }}>
                    <input ref={GfileRef} multiple={true} accept=".png, .jpg, .jpeg" type="file" onChange={(e) => { handleGalleryupdate(e,null);}} style={{ display: "none" }} />
                    <Box
                        onClick={() => { GfileRef.current && GfileRef.current.click() }}
                        // onDrop={handleDrop}
                        // onDragOver={handleDragOver}
                        sx={{ display: "flex", flexDirection: 'column', gap: "16px", border: "1px dashed grey", height: "150px", justifyContent: "center", alignItems: "center" }}>

                        <BackupIcon sx={{ color: "grey.500", fontSize: "32px", }} />
                        <TranslateText sx={{ color: "primary.dark" }} >Upload an Image</TranslateText>
                        <TranslateText sx={{ color: "grey.800" }} >PNG,JPG</TranslateText>

                    </Box>
                    <Grid container direction="row" sx={{ gap: "1rem", pt: "20px" }}>
                        {
                            product.gallery?.length>=1 && product.gallery.map((file, f_id) => {
                                return <Grid item key={f_id}>
                                    {
                                        <Chip label={product.gallery[f_id].name} deleteIcon={<Close />} onDelete={(e) => handleGalleryupdate(e,f_id)} />
                                    }
                                </Grid>
                            })
                        }
                    </Grid>
                </Paper>
                {/* <Divider  sx={{marginTop:"32px",border:"1px dashed grey"}}/> */}
            </Box>
            <Box sx={{ borderBottom: "1px dashed grey ", paddingBottom: "45px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>


                <TranslateText sx={[Styles.subheader, { alignSelf: "start" }]}>
                    Description
                </TranslateText>
                <Paper sx={{ padding: "32px", display: "flex", flexDirection: "column", gap: "36px", width: "60%" }}>

                    <Box>
                        <TranslateText sx={formStyle.label} color="grey.700" >Name <TranslateText component="span" color="error">*</TranslateText></TranslateText>
                        <InputBase sx={formStyle.inputBase} value={product.name} onChange={(e) => dispatch(updateProduct({ ...product, name: e.target.value }))} />
                        {errors.name && <TranslateText sx={{ color: "error.light" }}>{errors.name}</TranslateText>}
                    </Box>
                    <Box>
                        <TranslateText sx={formStyle.label} color="grey.700">Slug Name</TranslateText>
                        <InputBase sx={formStyle.inputBase} disabled value={product.slugname} onChange={(e) => dispatch(updateProduct({ ...product, slugname: e.target.value }))} />
                        {/* {errors.userNae && <TranslateText sx={{color:"error.light"}}>{errors.userName}</TranslateText>} */}
                    </Box>
                    <Box>
                        <TranslateText sx={formStyle.label} color="grey.700">Unit / Capacity <TranslateText component="span" color="error">*</TranslateText></TranslateText>
                        <InputBase sx={formStyle.inputBase} value={product.unit} onChange={(e) => dispatch(updateProduct({ ...product, unit: e.target.value }))} />
                        {errors.unit && <TranslateText sx={{ color: "error.light" }}>{errors.unit}</TranslateText>}
                    </Box>
                    <Box>
                        <TranslateText sx={formStyle.label} color="grey.700">Brief <TranslateText component="span" color="error">*</TranslateText></TranslateText>

                        <InputBase multiline rows={5} sx={formStyle.textBox} value={product.details} onChange={(e) => dispatch(updateProduct({ ...product, details: e.target.value }))} />
                        {/* <Editor  style={formStyle.textBox}  value={product.description} onTextChange={(e) => dispatch(updateProduct({ ...product, description: e.htmlValue }))} /> */}

                        {errors.description && <TranslateText sx={{color:"error.light"}}>{errors.description}</TranslateText>}
                    </Box>
                    <Box>
                        <TranslateText sx={formStyle.label} color="grey.700">Description <TranslateText component="span" color="error">*</TranslateText></TranslateText>

                        {/* <InputBase multiline rows={5} sx={formStyle.textBox} value={product.description} onChange={(e) => dispatch(updateProduct({ ...product, description: e.target.value }))} /> */}
                        {/* <Editor  style={formStyle.textBox}  value={product.description} onTextChange={(e) => dispatch(updateProduct({ ...product, description: e.htmlValue }))} /> */}

                        {errors.description && <TranslateText sx={{color:"error.light"}}>{errors.description}</TranslateText>}
                    </Box>
                </Paper>
                {/* <Divider  sx={{marginTop:"32px",border:"1px dashed grey"}}/> */}
            </Box>
            <Box sx={{ paddingBottom: "45px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>


                <TranslateText sx={[Styles.subheader, { alignSelf: "start" }]}>
                    Simple Product Information
                </TranslateText>
                <Paper sx={{ padding: "32px", display: "flex", flexDirection: "column", gap: "36px", width: "60%" }}>

                    <Box>
                        <TranslateText sx={formStyle.label} color="grey.700">Price <TranslateText component="span" color="error">*</TranslateText></TranslateText>
                        <InputBase sx={formStyle.inputBase} value={product.price} onChange={(e) => dispatch(updateProduct({ ...product, price: e.target.value }))} />
                        {errors.price && <TranslateText sx={{ color: "error.light" }}>{errors.price}</TranslateText>}
                    </Box>
                    <Box>
                        <TranslateText sx={formStyle.label} color="grey.700">Sale Price <TranslateText component="span" color="error">*</TranslateText></TranslateText>
                        <InputBase sx={formStyle.inputBase} value={product.sellingPrice} onChange={(e) => dispatch(updateProduct({ ...product, sellingPrice: e.target.value }))} />
                        {errors.sellingPrice && <TranslateText sx={{ color: "error.light" }}>{errors.sellingPrice}</TranslateText>}
                    </Box>
                    <Box>
                        <TranslateText sx={formStyle.label} color="grey.700">Quantity <TranslateText component="span" color="error">*</TranslateText></TranslateText>
                        <InputBase sx={formStyle.inputBase} value={product.quantity} onChange={(e) => dispatch(updateProduct({ ...product, quantity: e.target.value }))} />
                        {errors.quantity && <TranslateText sx={{ color: "error.light" }}>{errors.quantity}</TranslateText>}
                    </Box>
                    <Box>
                        <TranslateText sx={formStyle.label} color="grey.700">SKU <TranslateText component="span" color="error">*</TranslateText></TranslateText>
                        <InputBase sx={formStyle.inputBase} value={product.sku} onChange={(e) => dispatch(updateProduct({ ...product, sku: e.target.value }))} />
                        {errors.sku && <TranslateText sx={{color:"error.light"}}>{errors.sku}</TranslateText>}
                    </Box>
                    <Box>
                        <TranslateText sx={formStyle.label} color="grey.700">Width <TranslateText component="span" color="error">*</TranslateText></TranslateText>
                        <InputBase required sx={formStyle.inputBase} value={product.width} onChange={(e) => dispatch(updateProduct({ ...product, width: e.target.value }))} />
                        {errors.width && <TranslateText sx={{color:"error.light"}}>{errors.width}</TranslateText>}
                    </Box>
                    <Box>
                        <TranslateText sx={formStyle.label} color="grey.700">Height <TranslateText component="span" color="error">*</TranslateText></TranslateText>
                        <InputBase required sx={formStyle.inputBase} value={product.height} onChange={(e) => dispatch(updateProduct({ ...product, height: e.target.value }))} />
                        {errors.height && <TranslateText sx={{color:"error.light"}}>{errors.height}</TranslateText>}
                    </Box>
                    <Box>
                        <TranslateText sx={formStyle.label} color="grey.700">Length <TranslateText component="span" color="error">*</TranslateText></TranslateText>
                        <InputBase required sx={formStyle.inputBase} value={product.length} onChange={(e) => dispatch(updateProduct({ ...product, length: e.target.value }))} />
                        {errors.length && <TranslateText sx={{color:"error.light"}}>{errors.length}</TranslateText>}
                    </Box>
                    <Box>
                        <TranslateText sx={formStyle.label} color="grey.700">Tax <TranslateText component="span" color="error">*</TranslateText></TranslateText>
                        <InputBase sx={formStyle.inputBase} value={product.tax} onChange={(e) => dispatch(updateProduct({ ...product, tax: e.target.value }))} />
                        {errors.tax && <TranslateText sx={{ color: "error.light" }}>{errors.tax}</TranslateText>}
                    </Box>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        sx={{ flexDirection: "row" }}
                        value={product.status}
                        onChange={(e) => dispatch(updateProduct({ ...product, status: e.target.value }))}
                    >
                        <FormControlLabel value="draft" control={<Radio />} label="Draft" />
                        <FormControlLabel value="publish" control={<Radio />} label="Publish" />
                    </RadioGroup>
                    {errors.status && <TranslateText sx={{ color: "error.light" }}>{errors.status}</TranslateText>}
                </Paper>
                {/* <Divider  sx={{marginTop:"32px",border:"1px dashed grey"}}/> */}
            </Box>

            <Box sx={{ alignSelf: 'end' }}>
                <Button sx={formStyle.button} variant="contained" onClick={handleProduct}>{isloading? <CircularProgress color="inherit" size={30}/> : "Add Product"}</Button>
            </Box>
        </Stack>
        
        {/*---------------old code--------------*/}
    </Box>)

}

export default ProductForm 