import { Download, Edit, Send } from "@mui/icons-material"
import { Box, Button, Divider, Grid, InputBase, MenuItem, Select, Stack, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography ,Breadcrumbs,Modal,RadioGroup,Radio,FormControlLabel,Tooltip} from "@mui/material"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useMediaQuery } from "react-responsive"
import IndianNumFormat from "./numberFormat"
import { PDFViewer, BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import Invoice from "./invoice"
import CustomAxios from "../utils/CustomAxios"
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from "../redux/snackbar"
import { jwtDecode } from "jwt-decode"
import MessageBar from "../modules/messageBar"
import { updateAllOrders,} from "../redux/orders"
import { useNavigate } from "react-router-dom"
import { InfoOutlined } from "@mui/icons-material";
// import { PDFViewer, BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import { TranslateText } from "../utils/googletranslator";
const AdminOrderDetails=({ orderId })=>{
    const dispatch = useDispatch()
    const admin = jwtDecode(localStorage.getItem('authtoken'))?.role
    console.log(admin)
    let allOrders = useSelector(state => state.orders.AllOrders)
    let filteredOrder=useSelector(state=>state.orders.filteredOrders)
    const [order_details, setOrderDetails] = useState()
    const [orderSteps,setOrderSteps] = useState([])
    // const [orderSteps, setOrderSteps] = useState(0)
    const [orderStatus, setOrderstatus] = useState('')
    // const [isStepFailed,setIsStepFailed]=useState(false)
    const [statusModal,setStatusModal]=useState(false) 
    const [error,setError]=useState({})
    const [deliveryWaiveLimit,setDeliveryWaiveLimit]=useState(0) 
    const [totals,setTotals] = useState({
        sub_total:0,
        total:0
    })
    const [activeStatus,setActiveStatus]=useState(0)
    const [trackingId,setTracking_id] = useState(null)
    const [isTrackingEditable,setIsTrackingEditable] = useState(false)
    let platform_fee = 1.5/100
    const navigate = useNavigate()
    const convertDate = (isoString) => {
        
        const date = new Date(isoString);
      
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false, // For 24-hour format
        };
      
        return date.toLocaleString('en-US', options);
      };

    let handleModalStatus=()=>{
        setStatusModal(false)
      }
    useEffect(() => {
        let order = ""
        // if(orderId.startsWith("cash_")){
        //     order = allOrders.filter(order => order.cash_pickup_id === orderId)[0]
        // }
        // else{
        // console.log(orderId)
        order = allOrders.filter(order => order.razorpay_order_id === orderId || order.cash_pickup_id === orderId ||order.order_id==orderId )[0]
        if(!order){
            order=filteredOrder.filter(order => order.razorpay_order_id === orderId || order.cash_pickup_id === orderId ||order.order_id==orderId )[0]
        }
        // }
        // console.log(order?.self_pickup)
        if (!order?.self_pickup){
            setOrderSteps([<TranslateText>Order Placed</TranslateText> ,<TranslateText>In Progress</TranslateText> ,<TranslateText>Order Shipped</TranslateText> ,<TranslateText>Delivered</TranslateText> ])
        }
        else if(order?.self_pickup){
            setOrderSteps([<TranslateText>Order Placed</TranslateText>,<TranslateText>In Progress</TranslateText>,<TranslateText>Delivered</TranslateText>])
        }
        if (order?.status=='In Progress'){
            setActiveStatus(1)
            
        }
        else if(order?.status=='Order Shipped'){
            setActiveStatus(3)   

        }
        else if (order?.status=='Order Cancelled'){
            let order_step=[...orderSteps]
            order_step[order_step.length - 1] = 'Cancelled'
            // setIsStepFailed(true)
            console.log(order_step)
            setOrderSteps(order_step)
            setActiveStatus(4)
        }
        else {
            setActiveStatus(4)
        }
        setOrderstatus(order?.status)
        CalculateTotals(order)
        setOrderDetails(order)
        setTracking_id(order?.tracking_id)
    }, [orderId,])
    useEffect(() => {
        CustomAxios.get('get-delivery-settings/1/').then((res) => {
          let res_data = res.data
          if (res_data.deliverySettings_id) {
            setDeliveryWaiveLimit(res_data.waiver_amt)
          }
          else if (res_data.error) {
            dispatch(updateSnackBarOpen(true))
            dispatch(updateSnackBarSeverity('error'))
            dispatch(updateSnackBarMessage("Something Went wrong."))
            // setIsLoading(true)
    
          }
        }).catch((err) => {
          console.error(err)
        })
      }, [])
    const CalculateTotals = (order_details)=>{
        let sub_total = 0
        let total = 0
        if(order_details?.carts && order_details.carts.length >=1){
            order_details?.carts.map((cart)=>{
                sub_total += parseFloat(cart?.price? cart?.price:cart?.product.selling_price) * parseFloat(cart.quantity)
            })
            setTotals({...totals,sub_total:sub_total})
        }
    }    
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });
    const isTablet= useMediaQuery({ maxWidth: 900,minWidth:600 });

    const get_orders = ()=>{
        // if(isTrackingSendClicked){
            // // console.log("------------------clicked---------",isTrackingSendClicked)
            CustomAxios.get('listOrders/').then((res) => {
                dispatch(updateAllOrders(res.data))
                let order = res.data.filter(order => order.razorpay_order_id === orderId)[0]
                // // console.log(order.tracking_id)
                CalculateTotals(order)
                setOrderDetails(order)
                setTracking_id(order?.tracking_id)
            })
        // }
    }



    const handleUpdateTrackingId = (order_id)=>{
        if(trackingId){
            CustomAxios.put("update_order/"+orderId,{"tracking_id":trackingId}).then((res)=>{
                if(res.data.error){
                    dispatch(updateSnackBarMessage(res.data.error))
                    dispatch(updateSnackBarSeverity("error"))
                }
                else if(res.data){
                    get_orders()
                    dispatch(updateSnackBarMessage(res.data))
                    dispatch(updateSnackBarSeverity("success"))
                    setIsTrackingEditable(false)
                }
            }).catch((err)=>{
                console.error(err)
            })
        }
        else{
            dispatch(updateSnackBarMessage("    Please Enter a valid Tracking Id    "))
            dispatch(updateSnackBarSeverity("error"))
        }
        dispatch(updateSnackBarOpen(true))
    }
    
    const handleChangeStatus = (e) => {
        e.preventDefault()
        let payload = {
            order_id:orderId,
            status:orderStatus
        }
        if (orderStatus!="" ){
            CustomAxios.post("change-status/",payload).then((res)=>{
                if(res.data.error){
                    dispatch(updateSnackBarMessage(res.data.error))
                    dispatch(updateSnackBarOpen(true))
                    dispatch(updateSnackBarSeverity('error'))
                }
                else if(res.data.order_id){
                    let order_id =  res.data.order_id
                    let order_status =  res.data.status
                    console.log(res.data)
                    setOrderDetails({...order_details,status:order_status})
                    // navigate("/orders")
                    if (order_status=='In Progress'){
                        setActiveStatus(1)
                        
                    }
                    else if(order_status=='Order Shipped'){
                        setActiveStatus(3)   
            
                    }
                    else if (order_status=='Order Cancelled'){
                        let order_step=[...orderSteps]
                        order_step[order_step.length - 1] = 'Cancelled'
                        // setIsStepFailed(true)
                        console.log(order_step)
                        setOrderSteps(order_step)
                        setActiveStatus(4)
                    }
                    else {
                        setActiveStatus(4)
                    }
                }
                setStatusModal(false)
            }).catch((err)=>{
                console.error(err)
            })

        }
        else{
            setError({status:"Please select status! "})

        }
       
    }
let paymetnMethod=(val)=>{
    console.log("????????????????????????????????????????",val)
    // if(!val){
    //     return <TranslateText sx={{color:"#64748B",fontSize:"14px",fontWeight:400}}>{placeholder}</TranslateText>
    //   }
    //   else {
        if (val=='cashfree'||val=='cashfree'){
          return "Online Payment"
        }
        else if(val==="mayicash"){
          return "Mayi Cash"
        }
        else if(val==="cod"){
          return "Cash On Delivery"
  
        }
        else if(val==="cash"){
          return "Cash On Pickup"
        }
        else{
          return val
        }
    //   }
    
}
const handlePlaceholder = (val,placeholder)=>{
    console.log(val)
    if(!val){
      return <TranslateText sx={{color:"#64748B",fontSize:"14px",fontWeight:400}}>{placeholder}</TranslateText>
    }
    return val
    // return val
  }
//   console.log(order_details,deliveryWaiveLimit,parseFloat(order_details?.amount)+parseFloat(order_details?.tax),deliveryWaiveLimit , order_details?.self_pickup)
// const breakText = (text, width) => {
//     const words = text.split(' ');
//     let currentLine = '';
//     const lines = [];

//     for (const word of words) {
//       if ((currentLine + word).length * 10 <= width) {
//         currentLine += `${word} `;
//       } else {
//         lines.push(currentLine.trim());
//         currentLine = `${word} `;
//       }
//     }
//     lines.push(currentLine.trim());

//     return lines;
//   };

//   const lines = breakText(order_details?.shippings.address+" "+order_details?.shippings.town_city+" "+order_details?.shippings.state+" "+order_details?.shippings.pin, '250px');
    return<Box  className="background"  sx={{  width: {xs:"calc(100% )",sm:"100%"}, p: {xs:"0px",sm:"20px"}, bgcolor:"#fff" }}>
    <MessageBar/>
    <Box   sx={{ display:"flex",flexDirection:"column",gap:"24px"}}>
    {admin=="admin" && <Box sx={{display:"flex",flexDirection:"column",gap:"16px"}}>
        <TranslateText sx={{ fontSize: "18px", lineHeight: "28px", fontWeight: "500", color: "#6F7883" }}>Order Management</TranslateText>
        
          <Breadcrumbs separator=">" sx={{pr:"8px"}}>
            <TranslateText component={Link} to='/orders' underline="none" color="inherit" sx={{fontSize:"14px",fontWeight:400,lineHeight:"20px",textDecoration:"none"}}>Overview</TranslateText>
            <TranslateText sx={{color:"#1099F4",fontWeight:700,fontSize: "14px", lineHeight: "20px"}}>Order Details</TranslateText>
        </Breadcrumbs>
    </Box>}
    <Box sx={{display:"flex",justifyContent:"space-between"}}>
        <Box>
            <TranslateText sx={{fontSize:"18px",fontWeight:"600",lineHeight:"28px",}}>Order Id:{orderId}</TranslateText>
            <TranslateText sx={{color:"#6F7883",fontWeight:400,fontSize: "14px", lineHeight: "20px"}}>{order_details?.carts?.length} Item | Order Placed On  {convertDate(order_details?.created_on)}</TranslateText>
            <TranslateText sx={{color:"#6F7883",fontWeight:400,fontSize: "14px", lineHeight: "20px"}}>Payment Status: {paymetnMethod(order_details?.paymentmethod)}({order_details?.payment_status?"Completed":"In Progress"})</TranslateText>
           
            <PDFDownloadLink document={<Invoice order_details = {order_details} deliveryWaiveLimit={deliveryWaiveLimit} />}  style={{fontFamily:"Popins"}}fileName={`${orderId}.pdf`}>
                <TranslateText sx={{ fontWeight: 400, fontSize:"14px",lineHeight:"20px"}}>Download Invoice</TranslateText>
            </PDFDownloadLink>

        </Box>
        <Box sx={{display:"flex",flexDirection:"column",gap:"8px",alignSelf:"flex-end"}}>
        {/* <Stack direction="row" sx={{ gap: "1rem", alignItems: "center"}}>
                <TranslateText sx={{ fontSize:  {xs:"14px",sm:"16px"}, fontWeight: 700 }}>Order Status:</TranslateText>
                <TranslateText sx={{ fontSize: "14px", fontWeight: 500 }}>{order_details?.status}</TranslateText>
            </Stack> */}
        {admin=="admin" && (order_details?.status === "In Progress"||order_details?.status === "Order Shipped")  &&<Box> <Box sx={{display:"flex",alignItems:"center",gap:"24px"}}><Select   sx={{ gap: "8px", borderRadius: "4px", height: "36px", width: "185px", "& .MuiSelect-select": { display: "flex", alignItems: "flex-end" } ,"& .MuiOutlinedInput-notchedOutline": {borderColor: "#CBD5E1",},"&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#CBD5E1",},"&.Mui-focused .MuiOutlinedInput-notchedOutline": {borderColor:"#CBD5E1"}}} displayEmpty onChange={(e)=>{setOrderstatus(e.target.value)}} renderValue={(val)=>handlePlaceholder(val,'Select Status')} value={orderStatus}>
        
            <MenuItem value="In Progress">In Progress</MenuItem>
            {!order_details?.self_pickup&& <MenuItem value="Order Shipped">Order Shipped</MenuItem>}
            <MenuItem value="Order Completed">Order Completed</MenuItem>
            <MenuItem value="Order Cancelled">Order Cancelled</MenuItem>
           
        </Select>
        <Button variant="contained" sx={{textTransform:"none"}} onClick={(e)=>handleChangeStatus(e)}><TranslateText>Change Status</TranslateText></Button></Box>
        <TranslateText sx={{ color: "error.light" }}>{error.status}</TranslateText></Box>}
        {/* <TranslateText sx={{}} >Status</TranslateText>
        <InputBase value={orderStatus} onKeyDown={(e)=>setStatusModal(true)} onClick={(e)=>setStatusModal(true)} sx={{border:"1px  #CBD5E1 solid",paddingLeft:"4px",width:"200px",height:"36px"}}></InputBase> */}
        </Box>
        
    </Box>
    {orderSteps.length>0&&
    <Stack sx={{ py: "28px" }}>
        <Stepper alternativeLabel={isMobile?false:true} activeStep={activeStatus} orientation={isMobile? "vertical":"horizontal"}>
            {
                orderSteps?.map((status, id) => {
                    // if (status=='Cancelled'){
                    //     return<Step key={id}>
                    //     <StepLabel sx={{color:"error.light"}}>{status}</StepLabel>
                    // </Step>
                    // }
                    // else{
                        return <Step key={id}>
                        <StepLabel >{status}</StepLabel>
                    </Step>
                    // }
                    
                })
            }
        </Stepper>
    </Stack>}
    <Box sx={{display:"flex",flexDirection:"column",gap:"16px"}}>
        <TranslateText sx={{fontSize:"18px",lineHeight:"28px",fontWeight:"600"}}>Products</TranslateText>
        <TableContainer>
        <Table>
            <TableHead sx={{ bgcolor: "#E2E8F0" }}>
                <TableRow>
                    <TableCell sx={{ textAlign: "left" }}><TranslateText>Products</TranslateText> </TableCell>
                    <TableCell sx={{ textAlign: "center" }}><TranslateText>Quantity</TranslateText> </TableCell>
                    <TableCell sx={{ textAlign: "center" }}><TranslateText>Price</TranslateText> </TableCell>             
                    <TableCell sx={{ textAlign: "center" }}><TranslateText>Sub Total</TranslateText> </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    order_details?.carts && order_details.carts?.map((cart, p_id) => {
                        
                        return (

                            <TableRow key={p_id}>
                                <TableCell sx={{ textAlign: "left" }}>
                                    <Stack direction="row" sx={{ gap: "1rem", alignItems: "center" }}>
                                        {cart.product.cover_details?.original && <Box component="img" src={cart.product.cover_details.original} sx={{ width: "50px", height: "50px" }}></Box>}
                                        <TranslateText sx={{ color: "#666666" }}>{cart.product.name}</TranslateText>
                                    </Stack>
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>{cart.quantity} X</TableCell>
                                <TableCell sx={{ textAlign: "center" }}>{IndianNumFormat(parseFloat(cart?.price? cart?.price :cart?.product.selling_price),true)}</TableCell>
                                
                                <TableCell sx={{ textAlign: "center" }}>
                                    <TranslateText>{IndianNumFormat(parseFloat(cart?.price? cart?.price:cart?.product.selling_price)* parseFloat(cart.quantity),true)}</TranslateText>
                                </TableCell>
                            </TableRow>
                        )
                    })
                }

            </TableBody>
        </Table>
        </TableContainer>
    </Box>
    <Box sx={{display:"flex",flexDirection:"column",gap:"16px"}}>
        <TranslateText sx={{fontSize:"18px",lineHeight:"28px",fontWeight:"600"}}>Billing and Payment</TranslateText>
        <Box sx={{display:"flex",gap:"24px",justifyContent:"space-between", flexDirection:{xs:"column-reverse",sm:"row"}}}>
            <Box sx={{border:"1px solid #D6DAE2 ",padding:"24px" ,display:"flex",gap:"16px",flexDirection:"column",minWidth:{xs:"270px",sm:"290px"} }}>
                <TranslateText sx={{fontSize: "18px", lineHeight: "28px", fontWeight: "500",}}>Shipping Address</TranslateText>
                <Box>
                <TranslateText sx={{fontSize: "16px", lineHeight: "24px", fontWeight: "400",}}>{order_details?.user_details.first_name=='non'?order_details.user_details.username : order_details?.user_details.first_name+" "+order_details?.user_details.last_name}</TranslateText>
                <TranslateText sx={{fontSize: "14px", lineHeight: "24px", fontWeight: "400",color:"#6F7883",maxWidth:"250px",}}>{order_details?.shippings.address}, {order_details?.shippings.town_city}, {order_details?.shippings.state}, {order_details?.shippings.pin}.</TranslateText>
                </Box>
                <Box>
                <TranslateText sx={{fontSize: "16px", lineHeight: "24px", fontWeight: "400",}}> Email ID:</TranslateText>
                <TranslateText sx={{fontSize: "14px", lineHeight: "24px", fontWeight: "400",color:"#6F7883"}}>{order_details?.user_details.email}</TranslateText>

                </Box>
                <Box>
                <TranslateText sx={{fontSize: "16px", lineHeight: "24px", fontWeight: "400",}}>Mobile Number:</TranslateText>
                <Typography sx={{fontSize: "14px", lineHeight: "24px", fontWeight: "400",color:"#6F7883"}}>{order_details?.paymentmethod=='cash'?  order_details?.user_details.mobileno:order_details?.shippings.mobileno}</Typography>
                </Box>
            </Box>
            <Box sx={{border:"1px solid #D6DAE2 ",padding:"24px" ,display:"flex",gap:"16px",flexDirection:"column",minWidth:{xs:"270px",sm:"290px"} }}>
                <TranslateText sx={{fontSize: "18px", lineHeight: "28px", fontWeight: "500",}}>Total Payment</TranslateText>
                <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                <TranslateText sx={{fontSize: "14px", lineHeight: "24px", fontWeight: "400",}}>Sub Total</TranslateText>
                <Typography sx={{fontSize: "14px", lineHeight: "24px", fontWeight: "400",color:"#6F7883"}}>{IndianNumFormat(parseFloat(totals.sub_total),true)}</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Box sx={{display:"flex",alignItems:"center"}}>
                    <TranslateText sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400" }}>Delivery Fees </TranslateText><Tooltip arrow title={<TranslateText>Free Shipping on orders over Rs. 3359.</TranslateText>} placement="top"><InfoOutlined sx={{ fontSize: "14px", ml: "5px" }} /></Tooltip>
                    </Box>
               
                <Typography sx={{fontSize: "14px",lineHeight: "24px",fontWeight: "400",color: "#6F7883",textDecoration:order_details?.deliveryBenefits ? "line-through": ""}}>{IndianNumFormat(parseFloat(order_details?.delivery_charge), true)}</Typography>
                </Box>
                <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                <TranslateText sx={{fontSize: "14px", lineHeight: "20px", fontWeight: "400",}}>Tax</TranslateText>
                <Typography sx={{fontSize: "14px", lineHeight: "24px", fontWeight: "400",color:"#6F7883"}}>{IndianNumFormat(parseFloat(order_details?.tax),true)}</Typography>
                </Box>
                <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                <TranslateText sx={{fontSize: "14px", lineHeight: "20px", fontWeight: "400",}}>Transaction fees</TranslateText>
                <Typography sx={{fontSize: "14px", lineHeight: "24px", fontWeight: "400",color:"#6F7883"}}>{IndianNumFormat(parseFloat(order_details?.cash_pickup_id? 0 : order_details?.transaction_fee),true)}</Typography>
                </Box>
                {/* <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                <TranslateText sx={{fontSize: "16px", lineHeight: "24px", fontWeight: "600",}}>Total</TranslateText>
                <TranslateText sx={{fontSize: "16px", lineHeight: "24px", fontWeight: "600",}}>{IndianNumFormat(parseFloat(order_details?.total_amount),true)}</TranslateText>
                </Box> */}
                {order_details?.discounts>0&& 
                <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                <TranslateText sx={{fontSize: "14px", lineHeight: "20px", fontWeight: "400",}}> Applied Incentives</TranslateText>
                <Typography sx={{fontSize: "14px", lineHeight: "24px", fontWeight: "400",color:"#6F7883"}}>-{ '₹'+IndianNumFormat(
                // parseFloat(order_details?.platform_fee) + 
                parseFloat(order_details?.discounts).toFixed(2))}</Typography>
                </Stack>}
                <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                <TranslateText sx={{ fontSize: "16px", fontWeight: 600 }}>Total</TranslateText>
                <Typography sx={{fontWeight:"600"}}>
                {
                IndianNumFormat(parseFloat(order_details?.total_amount)
                )
                }
                </Typography>
                </Stack>
            </Box>
        </Box>
        </Box>
        
    </Box>
    <Modal
    aria-labelledby="child-modal-title"
    aria-describedby="child-modal-description"
    open={statusModal}
        onClose={handleChangeStatus}
    >
        <Box sx={{position: "absolute",top:"50%",left:"50%", transform: 'translate(-50%, -50%)',bgcolor:"#fff",padding:"24px"}}>
        <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={orderStatus}
        onChange={(e)=>setOrderstatus(e.target.value)}
      >
        <FormControlLabel value="In Progress" control={<Radio />} label="In Progress" />
        <FormControlLabel value="Order Shipped" control={<Radio />} label="Order Shipped" />
        <FormControlLabel value="Order Completed" control={<Radio />} label="Order Completed" />
        <FormControlLabel value="Order Cancelled" control={<Radio />}label="Order Cancelled"/>
      </RadioGroup>
        <Box sx={{display:"flex",alignItems:"center",justifyContent:"center",gap:"20px"}}>
            
                <Button sx={{ textTransform:"none"}} variant="outlined" onClick={handleModalStatus}><TranslateText>Cancel</TranslateText></Button>
                <Box sx={{  padding: '8px 12px', borderRadius: "4px",cursor:'pointer',bgcolor:"#3B82F6" }}>
                <TranslateText sx={{color:"#fff",fontSize:"14px"}} onClick={(e)=>handleChangeStatus(e)}>Apply</TranslateText>
            </Box>
        </Box>
        </Box>
       
      

    </Modal>
        </Box>
}
export default AdminOrderDetails