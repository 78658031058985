import { Modal, Drawer,Box, } from "@mui/material"
import SignupForm from "../forms/signup_form"
import { useSelector } from "react-redux"
import LoginForm from "../forms/login_form"
import { useMediaQuery } from "react-responsive"
import { useEffect, useState } from "react"
import { updateOpen } from "../redux/congrats"
import { useDispatch } from "react-redux"
import confetti from '../assets/confetti.gif'
import { TranslateText } from "../utils/googletranslator"
import CloseIcon from '@mui/icons-material/Close';
import { inputStyles } from "../forms/signup_form"
import { useLocation } from "react-router-dom"
// import {useMediaQuery }from "react-responsive"

const Congrats = () => {
    const open = useSelector(state => state.congrats.open)
    const message = useSelector(state => state.congrats.message)
    const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });
    const OtpVerifyPopup=useSelector(state=>state.user.OtpVerifyPopup)
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const location=useLocation()

    const dispatch=useDispatch()

    const isMobile = useMediaQuery({ maxWidth: 600 });
    const toggleDrawer = (anchor, open) => {
        // console.log("????????????changed")
        // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        //   return;
        // }

        setState({ ...state, [anchor]: open });
    };
    useEffect(() => {
        if (open) {
            toggleDrawer('bottom', true)
        } else {
            toggleDrawer('bottom', false)
        }
    }, [open])
// console.log("???????????????????????????",open)
    return <> {!isMobile ? 
    <> 
    <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{ zIndex: "21 !important" }}
    >
        {/* {formType=='login'?<LoginForm/>:<SignupForm/>} */}
        <Box sx={[{padding: "16px", display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: 'column', bgcolor: "#fff", width: {xs:'100%',sm:"300px"} ,position: (location.pathname!=='/checkout'||isLaptop)&&"absolute",top:"50%",left:"50%", transform:(location.pathname!=='/checkout'||isLaptop)&& 'translate(-50%, -50%)',}]}>
        <Box sx={{ display: "flex", flexDirection: 'row', width: "100%", justifyContent: "flex-end", alignItems: "center" }}>
            <CloseIcon sx={{ fontWeight: "600", lineHeight: "24px", cursor: "pointer" }} onClick={()=>dispatch (updateOpen(false))} />
        </Box>


        <TranslateText sx={{ fontFamily: "poppins", fontWeight: 700, padding: "8px 0px", color: "transparent", background: "linear-gradient(79.69deg, #00C2FF -0.64%, #00E3AF 107.84%)", WebkitBackgroundClip: "text", backgroundClip: "text", textDecoration: "none" }}>HURRAY!</TranslateText> 
        <Box
                        sx={{
                          padding: "8px 12px",
                          display: "flex",
                          alignItems: "top",
                          gap: "14px",
                          
                        }}
                      >
                        <img src={confetti} alt="Gift" width="30" height="30" />
            
        <TranslateText sx={{ fontFamily: "poppins", fontWeight: 500, padding: "8px 0px", WebkitBackgroundClip: "text", backgroundClip: "text", textDecoration: "none" }}>You Got 25% Off</TranslateText>
        </Box>
        </Box>
    </Modal> 
    </>
    : <>
        {['bottom'].map((anchor) => {
            // <React.Fragment key={anchor}>
            {/* <Button onClick={}>{anchor}</Button> */ }
            {/* <InputBase value={selectedVoucher?selectedVoucher.voucher.name:""} sx={[formStyle.inputBase,{cursor:"pointer",textTransform:"capitalize"}]} onClick={toggleDrawer(anchor, true)} placeholder="Apply Coupon"  endAdornment={selectedVoucher? <HighlightOffIcon sx={{color:"#DA342E"}} onClick={()=>{setOpenCoupon(false);setSelectedVoucher()}} />  : <AddIcon sx={{color:"#1099F4"}} onClick={()=>toggleDrawer(anchor, true)} />}></InputBase> */ }
            return <Drawer
                anchor={anchor}
                open={state[anchor]}
            // onClose={toggleDrawer(anchor, false)}
            >
                <Box sx={[{padding: "16px", display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: 'column', bgcolor: "#fff", width: {xs:'100%',sm:"300px"} ,position: (location.pathname!=='/checkout'||isLaptop)&&"absolute",top:"50%",left:"50%", transform:(location.pathname!=='/checkout'||isLaptop)&& 'translate(-50%, -50%)',}]}>
        <Box sx={{ display: "flex", flexDirection: 'row', width: "100%", justifyContent: "flex-end", alignItems: "center" }}>
            <CloseIcon sx={{ fontWeight: "600", lineHeight: "24px", cursor: "pointer" }} onClick={()=>dispatch (updateOpen(false))} />
        </Box>


        <TranslateText sx={{ fontFamily: "poppins", fontWeight: 700, padding: "8px 0px", color: "transparent", background: "linear-gradient(79.69deg, #00C2FF -0.64%, #00E3AF 107.84%)", WebkitBackgroundClip: "text", backgroundClip: "text", textDecoration: "none" }}>HURRAY!</TranslateText> 
        <Box
                        sx={{
                          padding: "8px 12px",
                          display: "flex",
                          alignItems: "top",
                          gap: "14px",
                          
                        }}
                      >
                        <img src={confetti} alt="Gift" width="30" height="30" />
            
        <TranslateText sx={{ fontFamily: "poppins", fontWeight: 500, padding: "8px 0px", WebkitBackgroundClip: "text", backgroundClip: "text", textDecoration: "none" }}>You Got 25% Off</TranslateText>
        </Box>
        </Box>
            </Drawer>
            // </React.Fragment>
        })}</>

    }</>

}
export default Congrats